// extracted by mini-css-extract-plugin
export var deprecated = "Property-module--deprecated--7d84d";
export var description = "Property-module--description--b00f8";
export var exampleValue = "Property-module--example-value--51c66";
export var name = "Property-module--name--e5733";
export var row = "Property-module--row--5e297";
export var row__targeted = "Property-module--row__targeted--630c8";
export var schemaLink__tag = "Property-module--schemaLink__tag--e354c";
export var tags = "Property-module--tags--60ebd";
export var tags__schemaTag = "Property-module--tags__schemaTag--18de8";
export var tags__schemaTagValue = "Property-module--tags__schemaTagValue--9f3b2";
export var type = "Property-module--type--ddd71";