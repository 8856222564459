import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/geoip';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const GeoIpSchema = makeShortcode("GeoIpSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <GeoIpSchema json={responseJson.continent} jsonPointer="/continent" name="Response | Continent" services="*" mdxType="GeoIpSchema">
      <p>{`A JSON object containing information about the continent associated with the
IP address.`}</p>
      <Property name="code" mdxType="Property">
        <p>{`A two-character code for the continent associated with the IP address. The
possible codes are:`}</p>
        <ul>

          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`AF`}</inlineCode>
              {` – Africa`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`AN`}</inlineCode>
              {` – Antarctica`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`AS`}</inlineCode>
              {` – Asia`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`EU`}</inlineCode>
              {` – Europe`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`NA`}</inlineCode>
              {` – North America`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`OC`}</inlineCode>
              {` – Oceania`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`SA`}</inlineCode>
              {` – South America`}</p>


            <p {...{
              "parentName": "li"
            }}><a {...{
                "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRJSP2B14KSWWJTXKRWZGKJ",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "p"
              }}>{`Learn more about continent codes on our Knowledge
Base.`}</a></p>

          </li>

        </ul>
      </Property>
      <Property name="geoname_id" type="integer" mdxType="Property">
        <p>{`A unique identifier for the continent as specified by
`}
          <a {...{
            "href": "https://www.geonames.org/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GeoNames`}</a>
          {`.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRNFD5Z5EWNCAXM6SZZ5H2C",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about GeoNames IDs on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="names" mdxType="Property">
        <p>{`A map from locale codes, such as `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`en`}</inlineCode>
          {`, to the localized names for the
feature.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRGRYTGZB29ERDBZCX3MR8Q",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about localized geolocation names on our Knowledge
Base.`}</a></p>
      </Property>
    </GeoIpSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      