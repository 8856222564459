import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/geoip';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const GeoIpSchema = makeShortcode("GeoIpSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <GeoIpSchema json={responseJson.city} jsonPointer="/city" name="Response | City" services={['city', 'insights']} mdxType="GeoIpSchema">
      <p>{`A JSON object containing details about the city associated with the IP
address.`}</p>
      <Property name="confidence" services={['insights']} tags={{
        'Min': 0,
        'Max': 100
      }} type="integer" mdxType="Property">
        <p>{`A value from 0-100 representing our confidence that the city is correct.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRHZ767N9MJJ21K9CW04WWQ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about confidence factors on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="geoname_id" type="integer" mdxType="Property">
        <p>{`A unique identifier for the city as specified by
`}
          <a {...{
            "href": "https://www.geonames.org/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GeoNames`}</a>
          {`.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRNFD5Z5EWNCAXM6SZZ5H2C",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about GeoNames IDs on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="names" mdxType="Property">
        <p>{`A map from locale codes, such as `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`en`}</inlineCode>
          {`, to the localized names for the
feature.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRGRYTGZB29ERDBZCX3MR8Q",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about localized geolocation names on our Knowledge
Base.`}</a></p>
      </Property>
    </GeoIpSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      