import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson} jsonPointer="/" name="Response" services="*" mdxType="MinFraudSchema">
      <p>{`The following is an example of a complete minFraud Factors response, including
all available outputs. In practice, fewer outputs may be returned based on the
minFraud service you query and the inputs provided.`}</p>
      <Property name="id" tags={{
        'Format': 'UUID'
      }} mdxType="Property">
        <p>{`This is the minFraud ID, a `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Universally_unique_identifier",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`UUID`}</a>
          {`
that identifies the minFraud response. Use this ID to
`}
          <a {...{
            "href": "https://www.maxmind.com/en/minfraud-log",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`search your minFraud logs`}</a>
          {` or when
making support requests to MaxMind.`}</p>
      </Property>
      <Property name="risk_score" tags={{
        'Format': 'decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`This field contains the overall risk score, from 0.01 to 99. A higher score
indicates a higher risk of fraud. For example, a score of 20 indicates a
20% chance that a transaction is fraudulent. We never return a risk score
of 0, since all transactions have the possibility of being fraudulent.
Likewise we never return a risk score of 100.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408382414235",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the overall risk score on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="funds_remaining" tags={{
        'Format': 'decimal',
        'Min': 0.00
      }} mdxType="Property">
        <p>{`The approximate US dollar value of the funds remaining on your MaxMind
account.`}</p>
      </Property>
      <Property name="queries_remaining" tags={{
        'Min': 0
      }} type="integer" mdxType="Property">
        <p>{`The approximate number of queries remaining for the service before your
account runs out of funds.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | IP Address'} name="ip_address" mdxType="Property">
        <p>{`This object contains a risk score and (for minFraud Insights and Factors
requests) risk data for the IP address associated with the event.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Credit Card'} name="credit_card" services={['insights', 'factors']} mdxType="Property">
        <p>{`This object contains minFraud information related to the credit card.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Device'} name="device" services={['insights', 'factors']} mdxType="Property">
        <p>{`This object contains information about the device that MaxMind believes is
associated with the IP address passed in the request.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Email'} name="email" services={['insights', 'factors']} mdxType="Property">
        <p>{`This object contains information about the email address passed in the
request.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Shipping Address'} name="shipping_address" services={['insights', 'factors']} mdxType="Property">
        <p>{`This object contains minFraud response data associated with the shipping
address. If the shipping address was not provided in the request or could
not be parsed, this object will not be present in the response.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Shipping Phone'} name="shipping_phone" services={['insights', 'factors']} mdxType="Property">
        <p>{`This object contains minFraud response data associated with the shipping
phone. If the shipping phone was not provided in the request or could
not be parsed, this object will not be present in the response.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Billing Address'} name="billing_address" services={['insights', 'factors']} mdxType="Property">
        <p>{`This object contains minFraud response data associated with the billing
address. If the billing address was not provided in the request or could
not be parsed, this object will not be present in the response.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Billing Phone'} name="billing_phone" services={['insights', 'factors']} mdxType="Property">
        <p>{`This object contains minFraud response data associated with the billing
phone. If the billing phone was not provided in the request or could
not be parsed, this object will not be present in the response.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Disposition'} name="disposition" mdxType="Property">
        <p>{`This object contains information about how a request was handled by the
`}
          <a {...{
            "href": "https://www.maxmind.com/en/solutions/minfraud-services/custom-rules",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`custom rules`}</a>
          {`
you have defined. If your account does not have any custom rules defined,
then this object will not be present in the response.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Risk Score Reasons'} name="risk_score_reasons" services="factors" mdxType="Property">
        <Alert type="info" mdxType="Alert">
          <p>{`The risk reasons output codes and reasons are currently in beta and are subject to change.
These outputs are being actively developed and tested, and may undergo modifications that could impact their structure, format, and content.
While we strive to maintain stability, we recommend that you use these beta outputs with caution and avoid relying on them for critical applications.
Your feedback is valuable and will help us improve the final release.`}</p>
        </Alert>
        <p>{`This array contains objects that describe risk score reasons for a given transaction that change the risk score significantly.
Risk score reasons are usually only returned for medium to high risk transactions. If there were no significant changes to the risk score due to these reasons, then this array will not be present in the response.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Subscores'} name="subscores" services="factors" mdxType="Property">
        <p>{`This object contains risk factor scores for many of the individual
components that are used in calculating the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`risk_score`}</inlineCode>
          {`.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Warning'} name="warnings" mdxType="Property">
        <p>{`This array contains warning objects detailing issues with the request that
was sent such as invalid or unknown inputs. It is highly recommended that
you check this array for issues when integrating the web service.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      