import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.billing_address} jsonPointer="/billing_address" name="Response | Billing Address" services={['insights', 'factors']} mdxType="MinFraudSchema">
      <Property name="is_postal_in_city" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the postal code provided with the address is in the
city for the address. The field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` when the postal code is not in
the city. The key will only be present when a billing postal code, city,
and country have been provided.`}</p>
        <p>{`We use `}
          <a {...{
            "href": "https://www.geonames.org/postal-codes/postal-codes-us.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GeoNames data`}</a>
          {`
for the postal-city match, which uses the `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ZIP_Code#Preferred_place_names:_ZIP_Codes_and_previous_zoning_lines",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`preferred place name`}</a>
          {`
for a US ZIP code. `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ZIP_Code#Preferred_place_names:_ZIP_Codes_and_previous_zoning_lines",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Alternative place names`}</a>
          {`
for US ZIP codes may not trigger a match for this field.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408737039515-Billing-and-Shipping-Address-Risk-Data#h_01FN6TV1Z2BRWCHVBK1ZE8276E",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the postal to city check for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="latitude" tags={{
        'Format': 'Decimal'
      }} mdxType="Property">
        <p>{`The approximate `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/World_Geodetic_System",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`WGS84`}</a>
          {`
latitude associated with the address.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p><strong {...{
              "parentName": "p"
            }}>{`Latitude and longitude are not precise and should not be used to
identify a particular street address or household.`}</strong></p>
        </Alert>
      </Property>
      <Property name="longitude" tags={{
        'Format': 'Decimal'
      }} mdxType="Property">
        <p>{`The approximate `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/World_Geodetic_System",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`WGS84`}</a>
          {`
longitude associated with the address.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p><strong {...{
              "parentName": "p"
            }}>{`Latitude and longitude are not precise and should not be used to
identify a particular street address or household.`}</strong></p>
        </Alert>
      </Property>
      <Property name="distance_to_ip_location" type="integer" mdxType="Property">
        <p>{`The distance in kilometers from the address to the IP location. We fall back
to country or subdivision information if we do not have postal or city
information for an IP address, which may lead to inaccurate distance
calculations.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408737039515-Billing-and-Shipping-Address-Risk-Data#h_01FN6TVDXWSBQR55FJ0K2KWGJQ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the IP geolocation to address distance for risk analysis
on our Knowledge Base.`}</a></p>
      </Property>
      <Property name="is_in_ip_country" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the address is in the IP country. The field is
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` when the address is not in the IP country. If the IP address could
not be geolocated or no billing address was provided, the field will not be
included in the response.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408737039515-Billing-and-Shipping-Address-Risk-Data#h_01FN6TVDXWSBQR55FJ0K2KWGJQ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the IP location to country check for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      