import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`You can find a complete list of official and unofficial client APIs, and
third-party integrations on the
`}
      <a {...{
        "href": "/geoip/docs/databases#client-apis",
        "parentName": "p"
      }}>{`database documentation page`}</a>
      {`.`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      