import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Alert type="warning" mdxType="Alert">
      <p><em {...{
          "parentName": "p"
        }}>{`*`}
          {` The coordinates are not precise and should not be used to identify a
particular street address or household. To better represent a level of
accuracy, please include the `}
          <inlineCode {...{
            "parentName": "em"
          }}>{`accuracy_radius`}</inlineCode>
          {` when displaying latitude and
longitude and make it clear that the coordinates refer to a larger
geographical area instead of a precise location.`}</em></p>
    </Alert>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      