import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/geoip';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const GeoIpSchema = makeShortcode("GeoIpSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <GeoIpSchema json={responseJson.represented_country} jsonPointer="/represented_country" name="Response | Represented Country" services="*" mdxType="GeoIpSchema">
      <p>{`A JSON object containing details about the country which is represented by
users of the IP address. For instance, the country represented by an overseas
military base.`}</p>
      <p><a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4414762983195-Country-level-and-City-level-Geolocation",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn about represented countries on our Knowledge Base.`}</a></p>
      <Property name="geoname_id" type="integer" mdxType="Property">
        <p>{`A unique identifier for the country as specified by
`}
          <a {...{
            "href": "https://www.geonames.org/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GeoNames`}</a>
          {`.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRNFD5Z5EWNCAXM6SZZ5H2C",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about GeoNames IDs on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="is_in_european_union" mdxType="Property">
        <p>{`This is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the registered country is a member state of the European
Union. Otherwise, the key is not included in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`represented_country`}</inlineCode>
          {`
object.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRMQN1B2WWTDM8HSQ20870M",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the European Union flag on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="iso_code" tags={{
        'Length': 2
      }} mdxType="Property">
        <p>{`A two-character `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ISO_3166-1",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`ISO 3166-1`}</a>
          {`
country code for the represented_country country.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRJSP2B14KSWWJTXKRWZGKJ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about ISO code data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="names" mdxType="Property">
        <p>{`A map from locale codes, such as `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`en`}</inlineCode>
          {`, to the localized names for the
feature.`}</p>
      </Property>
      <Property name="type" mdxType="Property">
        <p>{`The type of represented country. Currently limited to `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`military`}</inlineCode>
          {`, but may
include other types in the future.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRGRYTGZB29ERDBZCX3MR8Q",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about localized geolocation names on our Knowledge
Base.`}</a></p>
      </Property>
    </GeoIpSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      