import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`In order to test the web services in the Sandbox environment, you will need to
generate a license key from your Sandbox account.
`}
      <a {...{
        "href": "https://support.maxmind.com/hc/en-us/articles/4407111582235-Generate-a-License-Key",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`Learn how to generate a license key on our Knowledge Base.`}</a></p>
    <p>{`The process is the same for your Sandbox account, but you must login to your
Sandbox account
[`}
      <a {...{
        "href": "https://sandbox.maxmind.com/en/accounts/current/license-key",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`direct link`}</a>
      {`,
Sandbox account login required] rather than your regular MaxMind account.`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      