import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`MaxMind maintains a Sandbox environment so that you can do basic integration
tests with the web services. In order to set up a Sandbox account you must
already have a paid account, and you must be an account administrator.
`}
      <a {...{
        "href": "https://support.maxmind.com/hc/en-us/articles/8604168846363",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`Learn how to set up a Sandbox account on our Knowledge Base.`}</a></p>
    <p>{`Once you have your Sandbox account set up and activated, follow the steps below.`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      