import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.shopping_cart} jsonPointer="/shopping_cart" name="Request | Shopping Cart" mdxType="MinFraudSchema">
      <p>{`This is an `}
        <em {...{
          "parentName": "p"
        }}>{`array`}</em>
        {` of `}
        <a {...{
          "href": "#schema--request--shopping-cart--item",
          "parentName": "p"
        }}>{`shopping cart item objects`}</a>
        {`.
A shopping cart should consist of an array of one or more item objects.`}</p>
      <p><a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/5452293435675-Order-and-Shopping-Cart-Inputs#h_01G0Z51P845R5DG7TCRNSKAD44",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn more about the shopping cart inputs on our Knowledge Base.`}</a></p>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      