import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/geoip';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const GeoIpSchema = makeShortcode("GeoIpSchema");
const Property = makeShortcode("Property");
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <GeoIpSchema json={responseJson.maxmind} jsonPointer="/maxmind" name="Response | MaxMind" services="*" mdxType="GeoIpSchema">
      <p>{`A JSON object containing information related to your MaxMind account.`}</p>
      <Property name="queries_remaining" type="integer" mdxType="Property">
        <p>{`The approximate number of remaining queries available for the end point
which is being called.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`This field is not present in the GeoLite2 City web service.`}</p>
        </Alert>
      </Property>
    </GeoIpSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      