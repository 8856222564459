import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.email} jsonPointer="/email" name="Response | Email" services={['insights', 'factors']} mdxType="MinFraudSchema">
      <Property linkToSchemaName="Response | Email | Domain" name="domain" mdxType="Property">
        <p>{`This field contains a JSON object with information relating to the domain.`}</p>
      </Property>
      <Property name="first_seen" tags={{
        'Format': 'YYYY-MM-DD',
        'Max Length': 10
      }} mdxType="Property">
        <p>{`A date string (e.g. 2017-04-24) to identify the date an email address was
first seen by MaxMind. This is expressed using the ISO 8601 date format
YYYY-MM-DD. The earliest date that may be returned is January 1, 2008.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408562891803-Email-Risk-Data#h_01FN6V59SHH0J0MRH041K46NE0",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use email first seen data for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="is_disposable" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if MaxMind believes that the email address is from a
disposable email provider. It is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if the address is not from a known
disposable email provider. The key will only be present if a valid email
address or email domain is provided.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408562891803-Email-Risk-Data#h_01FN6V5QYMX2DYRB4YSFM93F8D",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use disposable email detection for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="is_free" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if MaxMind believes that this email domain is for a
free email provider such as Gmail or Yahoo! Mail. It is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if the
domain is not for a known free email provider. The key will only be present
if a valid email address or email domain is provided.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408562891803-Email-Risk-Data#h_01FN6V5QYMX2DYRB4YSFM93F8D",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use free email detection for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="is_high_risk" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if MaxMind believes that this email address is likely
to be used for fraud. It is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if MaxMind does not believe the address
is used for fraud. The key will only be present if a valid email address or
email address hash is provided. Note that this is also factored into the
overall `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`risk_score`}</inlineCode>
          {` in the response as well.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408562891803-Email-Risk-Data#h_01FN6V50N3JM0YV92SJMJSRR37",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use our high risk email flag for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      