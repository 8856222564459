import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.account} jsonPointer="/account" name="Request | Account" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains account information for the end-user on the site where
the event took place.`}</p>
      <Property name="user_id" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`A unique user ID associated with the end-user in your system. If your system
allows the login name for the account to be changed, this should not be the
login name for the account, but rather should be an internal ID that does
not change. This is not your MaxMind account ID. No specific format is
required.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452006111003#h_01G1E0XKAEF0BBC336545SY2AA",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/account/user_id`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property isDeprecated name="username_md5" tags={{
        'Max Length': 32
      }} mdxType="Property">
        <p>{`An MD5 hash as a hexadecimal string of the username or login name associated
with the account.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      