import SandboxIntro from '/content/_sandbox-intro.mdx';
import SandboxLicenseKey from '/content/_sandbox-license-key.mdx';
import SandboxTestData from "../../../../content/geoip/docs/web-services/_sandbox-geoip-test-data.mdx";
import SandboxLimits from '/content/_sandbox-limits.mdx';
import SandboxLearnMore from '/content/_sandbox-learn-more.mdx';
import * as React from 'react';
export default {
  SandboxIntro,
  SandboxLicenseKey,
  SandboxTestData,
  SandboxLimits,
  SandboxLearnMore,
  React
};