import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.warnings} jsonPointer="/warnings" name="Response | Warnings" mdxType="MinFraudSchema">
      <p>{`This array contains `}
        <a {...{
          "href": "#schema--response--warning",
          "parentName": "p"
        }}>{`warning objects`}</a>
        {` detailing
issues with the request that was sent such as invalid or unknown inputs. It is
highly recommended that you check this array for issues when integrating the
web service.`}</p>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      