import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.ip_address.risk_reasons[0]} jsonPointer="/ip_address/risk_reasons/{n}" name="Response | IP Address | Risk Reason" services={['insights', 'factors']} mdxType="MinFraudSchema">
      <Property name="code" tags={{
        'Enum': null,
        'Max Length': 255
      }} mdxType="Property">
        <p>{`This value is a machine-readable code identifying the reason. Although more
codes may be added in the future, the current codes are:`}</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>{`Code`}</p>
              </th>
              <th>
                <p>{`Explanation`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`ANONYMOUS_IP`}</inlineCode></p>
              </td>
              <td>
                <p>{`The IP address belongs to an anonymous network. See the object at
`}
                  <inlineCode {...{
                    "parentName": "p"
                  }}>{`/ip_address/traits`}</inlineCode>
                  {` for more details.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`BILLING_POSTAL_VELOCITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Many different billing postal codes have been seen on this IP
address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`EMAIL_VELOCITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Many different email addresses have been seen on this IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`HIGH_RISK_DEVICE`}</inlineCode></p>
              </td>
              <td>
                <p>{`A high risk device was seen on this IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`HIGH_RISK_EMAIL`}</inlineCode></p>
              </td>
              <td>
                <p>{`A high risk email address was seen on this IP address in your past
transactions.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`ISSUER_ID_NUMBER_VELOCITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Many different issuer ID numbers have been seen on this IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`MINFRAUD_NETWORK_ACTIVITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Suspicious activity has been seen on this IP address across minFraud
customers.`}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408418812827-IP-Risk-Reasons",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use IP risk reasons for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="reason" mdxType="Property">
        <p>{`This field provides an explanation of the reason, as seen in the table
above. The explanation text may change at any time and should not be matched
against.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408418812827-IP-Risk-Reasons",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use IP risk reasons for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      