import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Alert = makeShortcode("Alert");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.custom_inputs} jsonPointer="/custom_inputs" name="Request | Custom Inputs" services="*" mdxType="MinFraudSchema">
      <p>{`Custom Inputs are optional inputs to the minFraud service that must first be
defined for your account. Select Custom Inputs from the Account Portal in
order to do so. See our `}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408216546203-Use-Custom-Inputs",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Custom Inputs documentation`}</a>
        {`
for more information.`}</p>
      <Alert type="warning" mdxType="Alert">
        <p><strong {...{
            "parentName": "p"
          }}>{`You should never send a full credit card number as an input.`}</strong>
          {` If you
attempt to send a full credit card number as an input, the minFraud
service will reject the input and issue a warning.`}</p>
        <p><a {...{
            "href": "#schema--request--credit-card",
            "parentName": "p"
          }}>{`Learn more about valid payment number inputs above.`}</a></p>
      </Alert>
      <Property name="your_custom_BOOLEAN_key" type="boolean" mdxType="Property">
        <p>{`A custom key of your choice, with a boolean value.`}</p>
      </Property>
      <Property name="your_custom_FLOAT_NUMBER_key" tags={{
        'Format': 'float',
        'Min': '-1e14',
        'Max': '1e14'
      }} type="number" mdxType="Property">
        <p>{`A custom key of your choice, with a floating number value.`}</p>
      </Property>
      <Property name="your_custom_PHONE_NUMBER_key" tags={{
        'Format': 'Phone number',
        'Max Length': 255
      }} type="string" mdxType="Property">
        <p>{`A custom key of your choice with a string value, formatted as a phone
number. Numbers, spaces and punctuation accepted, although spaces and
punctuation will be stripped. The following ASCII characters constitute
the accepted punctuation: `}
          {'` ~ ! @ # $ % ^ & * ( ) – _ = + ‘ ” ; : , < . > / ? \\ | [ ] { and }'}
          {`.`}</p>
      </Property>
      <Property name="your_custom_STRING_key" tags={{
        'Max Length': 255
      }} type="string" mdxType="Property">
        <p>{`A custom key of your choice with a string value. The null character is not
allowed.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      