import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/geoip';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const GeoIpSchema = makeShortcode("GeoIpSchema");
const Property = makeShortcode("Property");
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <GeoIpSchema json={responseJson.location} jsonPointer="/location" name="Response | Location" services={['city', 'insights']} mdxType="GeoIpSchema">
      <p>{`A JSON object containing specific details about the location associated with
the IP address.`}</p>
      <Property name="accuracy_radius" type="integer" mdxType="Property">
        <p>{`The approximate accuracy radius, in kilometers, around the latitude and
longitude for the geographical entity (country, subdivision, city or postal
code) associated with the IP address. We have a 67% confidence that the
location of the end-user falls within the area defined by the accuracy
radius and the latitude and longitude coordinates.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRHZZP6RAYSNZTYE4MQ3MWY",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn about the geolocation area defined by latitude, longitude, and
accuracy radius, on our Knowledge Base.`}</a></p>
      </Property>
      <Property name="average_income" type="integer" services={['insights']} mdxType="Property">
        <p>{`The average annual income associated with the IP address in US dollars. This
is only available for IP addresses in the US.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208479131-User-Context-Data#h_01FN9BTX4DQGT8W7JBX60A7PM5",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about average income data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="latitude" tags={{
        'Format': 'Decimal'
      }} mdxType="Property">
        <p>{`The approximate `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/World_Geodetic_System",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`WGS84`}</a>
          {`
latitude of the postal code, city, subdivision or country associated with
the IP address.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p><strong {...{
              "parentName": "p"
            }}>{`IP Geolocation Usage`}</strong></p>
          <p>{`The coordinates are not precise and should not be used to identify a
particular street address or household. To better represent a level of
accuracy, please include the `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`accuracy_radius`}</inlineCode>
            {` when displaying latitude and
longitude and make it clear that the coordinates refer to a larger
geographical area instead of a precise location.`}</p>
        </Alert>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRHZZP6RAYSNZTYE4MQ3MWY",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn about the geolocation area defined by latitude, longitude, and
accuracy radius, on our Knowledge Base.`}</a></p>
      </Property>
      <Property name="longitude" tags={{
        'Format': 'Decimal'
      }} mdxType="Property">
        <p>{`The approximate `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/World_Geodetic_System",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`WGS84`}</a>
          {`
longitude of the postal code, city, subdivision or country associated with
the IP address.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p><strong {...{
              "parentName": "p"
            }}>{`IP Geolocation Usage`}</strong></p>
          <p>{`The coordinates are not precise and should not be used to identify a
particular street address or household. To better represent a level of
accuracy, please include the `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`accuracy_radius`}</inlineCode>
            {` when displaying latitude and
longitude and make it clear that the coordinates refer to a larger
geographical area instead of a precise location.`}</p>
        </Alert>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRHZZP6RAYSNZTYE4MQ3MWY",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn about the geolocation area defined by latitude, longitude, and
accuracy radius, on our Knowledge Base.`}</a></p>
      </Property>
      <Property name="metro_code" type="integer" mdxType="Property">
        <p>{`Metro code is a geolocation target code from Google.`}</p>
      </Property>
      <Property name="population_density" type="integer" services={['insights']} mdxType="Property">
        <p>{`The estimated number of people per square kilometer. This is only available
for IP addresses in the US.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208479131-User-Context-Data#h_01FN9BTX4DQGT8W7JBX60A7PM5",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about population density data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="time_zone" mdxType="Property">
        <p>{`The time zone associated with location, as specified by the
`}
          <a {...{
            "href": "https://www.iana.org/time-zones",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`IANA Time Zone Database`}</a>
          {`, e.g.,
"America/New_York".`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRKYHGB5C5Y1G4Y3AW18PYC",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about time zone data on our Knowledge
Base.`}</a></p>
      </Property>
    </GeoIpSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      