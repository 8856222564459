import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import { FaCloud as WebServiceIcon, FaDatabase as DatabaseIcon, FaMapMarkerAlt as CityCountryIcon, FaBuilding as EnterpriseIcon, FaUserSecret as AnonymousIcon, FaWifi as ConnectionTypeIcon, FaSign as DomainIcon, FaServer as ISPIcon, FaGlobe as InsightsIcon, FaArrowAltCircleRight as GoToIcon } from 'react-icons/fa';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "id": "toc-geoip2-products-and-services"
    }}><h3 {...{
        "id": "geoip2-products-and-services",
        "parentName": "div"
      }}>{`GeoIP2 Products and Services`}</h3>
      <p {...{
        "parentName": "div"
      }}>{`GeoIP2 IP intelligence products and services can provide data on geolocation,
network information, anonymizer status, and more. Learn about the different
kinds of
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4407630528795-Choose-the-Right-Geolocation-Product",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`geolocation data`}</a>
        {`
and
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408200217371-Choose-the-IP-Intelligence-Data-you-Need",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`IP intelligence data`}</a>
        {`
in GeoIP2 products and services on our knowledge base.`}</p>
      <table>
        <thead>
          <tr>
            <th>
              {' '}
            </th>
            <th>
              <p><DatabaseIcon mdxType="DatabaseIcon" />
                {` Binary Database`}</p>
            </th>
            <th>
              <p><DatabaseIcon mdxType="DatabaseIcon" />
                {` CSV Database`}</p>
            </th>
            <th>
              <p><WebServiceIcon mdxType="WebServiceIcon" />
                {` Web Service`}
                {`*`}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p><CityCountryIcon mdxType="CityCountryIcon" />
                {` City and Country`}</p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/city-and-country/#binary-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/city-and-country/#csv-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/web-services",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><ISPIcon mdxType="ISPIcon" />
                {` ISP`}</p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/isp/#binary-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/isp/#csv-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p>{`Included in GeoIP2 City Plus and Insights web services.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><DomainIcon mdxType="DomainIcon" />
                {` Domain`}</p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/domain/#binary-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/domain/#csv-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p>{`Included in GeoIP2 City Plus and Insights web services.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><ConnectionTypeIcon mdxType="ConnectionTypeIcon" />
                {` Connection Type`}</p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/connection-type/#binary-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/connection-type/#csv-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p>{`Comparable data included in the GeoIP2 Insights web service.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><EnterpriseIcon mdxType="EnterpriseIcon" />
                {` Enterprise`}</p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/enterprise/#binary-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/enterprise/#csv-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p>{`Comparable data included in the GeoIP2 Insights web service.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><AnonymousIcon mdxType="AnonymousIcon" />
                {` Anonymous`}</p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/anonymous-ip/#binary-database",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/anonymous-ip/#csv-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p>{`Included in the GeoIP2 Insights web service.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><InsightsIcon mdxType="InsightsIcon" />
                {` Insights`}
                {`*`}
                {`*`}</p>
            </td>
            <td>
              <p>{`•`}</p>
            </td>
            <td>
              <p>{`•`}</p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/web-services",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <p><em {...{
                  "parentName": "p"
                }}>{`*`}
                  {` GeoIP2 web services provide more accurate geolocation than our
equivalent databases. `}
                  <a {...{
                    "href": "https://support.maxmind.com/hc/en-us/articles/4407630528795-Choose-the-Right-Geolocation-Product#h_01FJMQXVWPSDRGPC1TG8NYQZ6E",
                    "target": "_blank",
                    "rel": "nofollow noopener noreferrer",
                    "parentName": "em"
                  }}>{`Learn more about the differences between GeoIP2
web services and databases on our knowledge
base`}</a>
                  {`.`}</em>
                {`
`}
                <br />
                <em {...{
                  "parentName": "p"
                }}>{`*`}
                  {`*`}
                  {` The GeoIP2 Insights web service contains comparable
data to all of the databases and web services listed above.`}</em></p>
            </td>
          </tr>
        </tbody>
      </table></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      