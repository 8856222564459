import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.disposition} jsonPointer="/disposition" name="Response | Disposition" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains information about how a request was handled by the
custom rules you have defined. If your account does not have any custom rules
defined, then this object will not be present in the response.`}</p>
      <p><a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408801942811-Use-Custom-Rules-and-Dispositions",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn about custom rules and dispositions on our Knowledge Base.`}</a></p>
      <Property name="action" tags={{
        'Enum': null
      }} mdxType="Property">
        <p>{`This describes how the request was handled. The valid values are:`}</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>{`Action`}</p>
              </th>
              <th>
                <p>{`Explanation`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`accept`}</inlineCode></p>
              </td>
              <td>
                <p>{`This is the default value that is used if none of your custom rules
match the request.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`reject`}</inlineCode></p>
              </td>
              <td>
                {' '}
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`manual_review`}</inlineCode></p>
              </td>
              <td>
                {' '}
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`test`}</inlineCode></p>
              </td>
              <td>
                <p>{`This value can be used to test custom rules.`}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </Property>
      <Property name="reason" tags={{
        'Enum': null
      }} mdxType="Property">
        <p>{`This describes why the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`action`}</inlineCode>
          {` was set to a particular value. The valid
values are:`}</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>{`Reason`}</p>
              </th>
              <th>
                <p>{`Explanation`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`default`}</inlineCode></p>
              </td>
              <td>
                <p>{`No custom rules matched the request.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`custom_rule`}</inlineCode></p>
              </td>
              <td>
                <p>{`A custom rule was applied and set the action.`}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </Property>
      <Property name="rule_label" mdxType="Property">
        <p>{`The custom rule that was triggered. If you do not have custom rules set up,
the triggered custom rule does not have a label, or no custom rule was
triggered, the field will not be included in the response.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      