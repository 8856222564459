import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`The Sandbox environment imposes volume limits on API requests to prevent abuse.
The Sandbox should not be used to test the capacity of the production web
service in high volume situations.`}</p>
    <p>{`If you are running into the request limit and need help, please
`}
      <a {...{
        "href": "https://support.maxmind.com/hc/en-us/requests/new",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`reach out to our support team for assistance`}</a>
      {`.`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      