import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.billing} jsonPointer="/billing" name="Request | Billing" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains the billing address and contact information provided by
the end-user who initiated the event.`}</p>
      <p><a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/5447224594075-Billing-and-Shipping-Inputs#h_01G0YN48RWENDYCKN2J3RQK2S3",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn more about the billing address inputs on our Knowledge Base.`}</a></p>
      <Property name="first_name" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The first name of the end user as provided in their billing information.`}</p>
      </Property>
      <Property name="last_name" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The last name of the end user as provided in their billing information.`}</p>
      </Property>
      <Property name="company" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The company of the end user as provided in their billing information.`}</p>
      </Property>
      <Property name="address" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The first line of the user's billing address.`}</p>
      </Property>
      <Property name="address_2" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The second line of the user's billing address.`}</p>
      </Property>
      <Property name="city" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The city of the user's billing address.`}</p>
      </Property>
      <Property name="region" tags={{
        'Max Length': 4
      }} mdxType="Property">
        <p>{`The `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ISO_3166-2",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`ISO 3166-2 subdivision code`}</a>
          {`
for the user's billing address.`}</p>
      </Property>
      <Property name="country" tags={{
        'Max Length': 2
      }} mdxType="Property">
        <p>{`The two character `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`ISO 3166-1 alpha-2 country code`}</a>
          {`
of the user's billing address.`}</p>
      </Property>
      <Property name="postal" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The postal code of the user's billing address.`}</p>
      </Property>
      <Property name="phone_number" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The phone number without the country code for the user's billing address.
Punctuation characters will be stripped. After stripping punctuation
characters, the number must contain only digits.`}</p>
      </Property>
      <Property name="phone_country_code" tags={{
        'Max Length': 4
      }} mdxType="Property">
        <p>{`The country code for phone number associated with the user's billing
address. If you provide this information then you must provide at least one
digit.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      