import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`We've created a
`}
      <a {...{
        "href": "https://github.com/maxmind/geoip2-csv-converter",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`small utility program`}</a>
      {` to
allow you to convert a GeoIP2 CSV file's representation of IP addresses to
another format. You can choose between start/end IP addresses, with the
addresses represented as strings or integers.`}</p>
    <p>{`The program is available from our
`}
      <a {...{
        "href": "https://github.com/maxmind/geoip2-csv-converter/releases",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`geoip2-csv-converter GitHub project releases tab.`}</a></p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      