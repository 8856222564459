import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson} jsonPointer="/" name="Request" services="*" mdxType="MinFraudSchema">
      <p>{`Currently minFraud Score, minFraud Insights, and minFraud Factors use the same
request document format. The request consists of a JSON object with one or
more of the fields shown below. Each key in the top-level object maps to an
object or array as described below. New fields that apply to one or both may
be added in the future.`}</p>
      <p>{`String fields are limited to no more than 255 valid Unicode characters unless a
shorter length is specified; the null and newline characters are forbidden. Of
course, many fields also have additional constraints that limit the length. For
example, the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`ip_address`}</inlineCode>
        {` field cannot be longer than the longest valid
representation of an IPv6 address. Unless it must match a specific format, it is
valid for a string field to be empty.`}</p>
      <p>{`Boolean fields must be provided as JSON `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`true`}</inlineCode>
        {` or `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`false`}</inlineCode>
        {`.`}</p>
      <p>{`Unless otherwise specified, if the value meets the requirements for the field,
then it will not be modified. Beyond field specific exceptions, an exception to
this is if the value is provided as a type different from what we require. In
such cases we convert it to the required type if possible. For example, if you
provide a string field as a number, then it will be converted to a string, and
vice versa. This conversion happens only between numbers and strings.`}</p>
      <p>{`The entire request body is limited to 20,000 bytes. Requests larger than this
size will be rejected.`}</p>
      <Property linkToSchemaName={'Request | Device'} name="device" mdxType="Property">
        <p>{`This object contains information about the device used in the transaction.`}</p>
      </Property>
      <Property linkToSchemaName={'Request | Event'} name="event" mdxType="Property">
        <p>{`This object contains general information related to the event being scored.`}</p>
      </Property>
      <Property linkToSchemaName={'Request | Account'} name="account" mdxType="Property">
        <p>{`This object contains account information for the end-user on the site where
the event took place.`}</p>
      </Property>
      <Property linkToSchemaName={'Request | Email'} name="email" mdxType="Property">
        <p>{`This object contains information about the email address of the end-user
who initiated the event.`}</p>
      </Property>
      <Property linkToSchemaName={'Request | Billing'} name="billing" mdxType="Property">
        <p>{`This object contains the billing address and contact information provided by
the end-user who initiated the event.`}</p>
      </Property>
      <Property linkToSchemaName={'Request | Shipping'} name="shipping" mdxType="Property">
        <p>{`This object contains the shipping address and contact information provided
by the end-user who initiated the event.`}</p>
      </Property>
      <Property linkToSchemaName={'Request | Payment'} name="payment" mdxType="Property">
        <p>{`This object contains information from and about the payment process that
was used for the event.`}</p>
      </Property>
      <Property linkToSchemaName={'Request | Credit Card'} name="credit_card" mdxType="Property">
        <p>{`This object contains information provided by the end-user and the payment
processor about the credit card used for the for the event.`}</p>
      </Property>
      <Property linkToSchemaName={'Request | Order'} name="order" mdxType="Property">
        <p>{`This object contains information about the order associated with the event.`}</p>
      </Property>
      <Property linkToSchemaName="Request | Shopping Cart" name="shopping_cart" mdxType="Property">
        <p>{`This is an array of shopping cart item objects. A shopping cart should
consist of an array of one or more item objects.`}</p>
      </Property>
      <Property linkToSchemaName="Request | Custom Inputs" name="custom_inputs" mdxType="Property">
        <p>{`Custom Inputs are optional inputs to the minFraud service that must first be
defined for your account. Select “Custom Inputs” from the Account Portal in
order to do so. See our Custom Inputs documentation for more information.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      