import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.risk_score_reasons[0]} jsonPointer="/risk_score_reasons/{n}" name="Response | Risk Score Reason" services={['factors']} mdxType="MinFraudSchema">
      <p>{`This object contains risk score reasons for a given transaction that change the risk score significantly.`}</p>
      <Property name="multiplier" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 100
      }} mdxType="Property">
        <p>{`The factor by which the risk score is increased (if the value is greater than 1) or decreased (if the value is less than 1) for given risk reason(s). Multipliers greater than 1.5 and less than 0.66 are considered significant and lead to risk reason(s) being present.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Risk Score Reason | Multiplier Reason'} name="reasons" mdxType="Property">
        <p>{`This array contains objects that describe one of the reasons for the multiplier.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      