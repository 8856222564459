import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`Binary databases make use of the
`}
      <a {...{
        "href": "https://maxmind.github.io/MaxMind-DB/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`MaxMind DB file format`}</a>
      {`. MaxMind
provides official client APIs in the language listed below. The following client
APIs are open source and licensed under the Apache License, Version 2.0.`}</p>
    <p>{`You can also use the `}
      <a {...{
        "href": "https://github.com/maxmind/mmdbinspect",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`mmdbinspect tool`}</a>
      {`
(in beta), a command line interface built with Go, to look up one or more IPs
from one or more MMDB databases and receive output in a parsable JSON format.`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      