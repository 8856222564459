import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import { FaCloud as WebServiceIcon, FaDatabase as DatabaseIcon, FaMapMarkerAlt as CityCountryIcon, FaNetworkWired as ASNIcon, FaArrowAltCircleRight as GoToIcon } from 'react-icons/fa';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "id": "toc-geolite-databases-and-services"
    }}><h3 {...{
        "id": "geolite-databases-and-services",
        "parentName": "div"
      }}>{`GeoLite Databases and Services`}</h3>
      <p {...{
        "parentName": "div"
      }}>{`GeoLite2 databases and web services provide free geolocation and limited network
data for IP addresses. This data is less accurate than the data in our paid,
GeoIP2 products and services. You must
`}
        <a {...{
          "href": "https://www.maxmind.com/en/geolite2/signup",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`sign up for a free GeoLite2 account`}</a>
        {`
in order to download GeoLite2 databases or query the GeoLite2 web services.
GeoLite2 uses the same integration methods and documentation as GeoIP2 (linked
below), with a few minor modifications. It is recommended that you get started
by `}
        <a {...{
          "href": "/geoip/geolite2-free-geolocation-data",
          "parentName": "p"
        }}>{`reading about GeoLite2`}</a>
        {`.`}</p>
      <table>
        <thead>
          <tr>
            <th>
              {' '}
            </th>
            <th>
              <p><DatabaseIcon mdxType="DatabaseIcon" />
                {` Binary Database`}</p>
            </th>
            <th>
              <p><DatabaseIcon mdxType="DatabaseIcon" />
                {` CSV Database`}</p>
            </th>
            <th>
              <p><WebServiceIcon mdxType="WebServiceIcon" />
                {` Web Service`}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p><CityCountryIcon mdxType="CityCountryIcon" />
                {` City and Country`}</p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/city-and-country/#binary-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/city-and-country/#csv-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/web-services",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><ASNIcon mdxType="ASNIcon" />
                {` ASN`}</p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/asn/#binary-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p><a {...{
                  "href": "/geoip/docs/databases/asn/#csv-databases",
                  "parentName": "p"
                }}>{`Docs `}
                  <GoToIcon mdxType="GoToIcon" /></a></p>
            </td>
            <td>
              <p>{`Included in the GeoLite2 City Web Service.`}</p>
            </td>
          </tr>
        </tbody>
      </table></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      