import { FaMapMarkerAlt as CityCountryIcon, FaBuilding as EnterpriseIcon, FaUserSecret as AnonymousIcon, FaNetworkWired as ASNIcon, FaWifi as ConnectionTypeIcon, FaSign as DomainIcon, FaServer as ISPIcon } from 'react-icons/fa';
import * as React from 'react';
export default {
  CityCountryIcon,
  EnterpriseIcon,
  AnonymousIcon,
  ASNIcon,
  ConnectionTypeIcon,
  DomainIcon,
  ISPIcon,
  React
};