import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`In addition to our MaxMind DB binary format, we also offer GeoIP2 and GeoLite2
databases in a CSV format suitable for importing into a SQL database. The CSV
files are shipped as a single
`}
      <a {...{
        "href": "https://en.wikipedia.org/wiki/Zip_(file_format)",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`zip file`}</a>
      {`.`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      