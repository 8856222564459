import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.event} jsonPointer="/event" name="Request | Event" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains general information related to the event being scored.`}</p>
      <Property name="transaction_id" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`Your internal ID for the transaction. We can use this to locate a specific
transaction in our logs, and it will also show up in email alerts and
notifications from us to you. No specific format is required.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452006111003-Event-and-Account-Inputs#h_01G1E0X7TPRN41NZHH9TRFWHVN",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/event/transaction_id`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="shop_id" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`Your internal ID for the shop, affiliate, or merchant this order is coming
from. Required for minFraud users who are resellers, payment providers,
gateways and affiliate networks. No specific format is required.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452006111003-Event-and-Account-Inputs#h_01G1E0WZ6PPV7VVKCKQM5N9960",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/event/shop_id`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="time" mdxType="Property">
        <p>{`The date and time the event occurred. The string must be in the
`}
          <a {...{
            "href": "https://tools.ietf.org/html/rfc3339",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`RFC 3339`}</a>
          {` date-time format. The time
must be within the past year. If this field is not in the request, the
current time will be used.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p><strong {...{
              "parentName": "p"
            }}>{`It is not recommended to use this input when scoring live transactions
as they occur.`}</strong>
            {` However, it can be useful if you store transactions to be
submitted to the service for scoring later.`}</p>
          <p>{`Please note that you cannot submit times more than one year in the past.
If you submit an event time more than one year in the past, the current
time will be used to score the transaction, and a warning will be
returned.`}</p>
        </Alert>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452006111003-Event-and-Account-Inputs#h_01G0Z3WBNAP5Y8WNRR156EJBBF",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/event/time`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="type" tags={{
        'Enum': null
      }} mdxType="Property">
        <p>{`The type of event being scored. The valid types are:`}</p>
        <ul>

          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`account_creation`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`account_login`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`email_change`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`password_reset`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`payout_change`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`purchase`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`recurring_purchase`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`referral`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`survey`}</inlineCode></p>


            <p {...{
              "parentName": "li"
            }}><a {...{
                "href": "https://support.maxmind.com/hc/en-us/articles/5452006111003-Event-and-Account-Inputs#h_01G0Z3WMWJW3QMHN8AGWK054E6",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "p"
              }}>{`Learn more about the `}
                <inlineCode {...{
                  "parentName": "a"
                }}>{`/event/type`}</inlineCode>
                {` input on our Knowledge
Base.`}</a></p>

          </li>

        </ul>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      