import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.email} jsonPointer="/email" name="Request | Email" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains information about the email address of the end-user
who initiated the event.`}</p>
      <Property name="address" tags={{
        'Max Length': 255,
        'Type': 'Email or MD5 of Email'
      }} mdxType="Property">
        <p>{`This field must be either be a valid email address or an MD5 of the email
used in the transaction.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5451485951387",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/email/address`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="domain" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The domain of the email address used in the transaction. Do not include the
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`@`}</inlineCode>
          {` in this field.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`You do not need to pass the email domain input unless you are passing the
email address as an MD5 hash. `}
            <a {...{
              "href": "https://support.maxmind.com/hc/en-us/articles/5451485951387#h_01G0Z373C3H1QA68TTHVYMXGTT",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "p"
            }}>{`Learn more about hashed email inputs on our
Knowledge Base.`}</a></p>
        </Alert>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      