import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.ip_address.country} jsonPointer="/ip_address/country" name="Response | IP Address | Country" mdxType="MinFraudSchema">
      <p>{`This object contains country-level geolocation data associated with the IP
address associated with the event`}</p>
      <Property isDeprecated name="is_high_risk" services={['insights', 'factors']} type="boolean" mdxType="Property">
        <p>{`This field has been deprecated.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      