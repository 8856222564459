import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.subscores} jsonPointer="/subscores" name="Response | Subscores" services={['factors']} mdxType="MinFraudSchema">
      <p>{`This object contains risk factor scores for many of the individual components
that are used in calculating the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`risk_score`}</inlineCode>
        {`. `}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408382645915-Risk-Factor-Scores",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn more about risk factor
scores on our Knowledge Base.`}</a></p>
      <p>{`This object is only included with minFraud Factors.
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4407833140123-Compare-the-minFraud-Services",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn more about the differences between the minFraud services on our Knowledge Base.`}</a></p>
      <Property name="avs_result" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the AVS result. If present, this is a value in the range 0.01 to 99.`}</p>
      </Property>
      <Property name="billing_address" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the billing address. If present, this is a value in
the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410973621019-Billing-and-Shipping-Risk-Scores#h_01FN6R69DEYJREHS1XEGGMZP1Z",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the billing address risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="billing_address_distance_to_ip_location" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the distance between the billing address and the
location for the given IP address. If present, this is a value in the range
0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410973621019-Billing-and-Shipping-Risk-Scores#h_01FN6R6NKC6EV11YECN55R32FB",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the billing address distance risk score for risk analysis
on our Knowledge Base.`}</a></p>
      </Property>
      <Property name="browser" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the browser attributes such as the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`User-Agent`}</inlineCode>
          {` and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Accept-Language`}</inlineCode>
          {`. If present, this is a value in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408382525851-Device-Risk-Scores#h_01FN6HFD5KWFEWANTE1WYS1HBY",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the browser risk score for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="chargeback" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`Risk of IP address based on the number of chargebacks and high risk activity
sighted on your account and shop ID from similar IP networks. This is only
available to
users `}
          <a {...{
            "href": "/minfraud/report-a-transaction",
            "parentName": "p"
          }}>{`sending chargeback data to MaxMind`}</a>
          {`.
If present, this is a value in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408382525851-Device-Risk-Scores#h_01FN6HEPAK6034Z03NJCQ4F8EK",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the chargeback risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="country" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the country the transaction originated from. If
present, this is a value in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408382525851#h_01G1EBAN6V6E3EFHC4SC3J9K9M",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the country risk score for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="country_mismatch" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the combination of IP country, card issuer country,
billing country, and shipping country. If present, this is a value in the
range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410973728667-Order-Detail-Risk-Scores#h_01FN6RDP31Y1ST71YB7W58RFRS",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the country mismatch risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="cvv_result" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the CVV result. If present, this is a value in the
range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410743622043-Credit-Card-Risk-Scores#h_01FN6RANC2E9Z6179XGNSNDWYJ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the CVV result risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="device" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the device. If present, this is a value in the
range 0.01 to 99.`}</p>
        <p>{`You must have device tracking enabled on your site to receive this risk
factor score. `}
          <a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4407973175451",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about device tracking on our Knowledge
Base.`}</a></p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408382525851-Device-Risk-Scores#h_01FN6HF0G2CJ5T15H3GJHNQ7XQ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the device risk score for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="email_address" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the particular email address. If present, this is a
value in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410757081243-Email-Risk-Scores#h_01FN6RDP31Y1ST71YB7W58RFRS",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the email risk score for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="email_domain" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The general risk associated with the email domain. If present, this is a
value in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410757081243-Email-Risk-Scores#h_01FN6RE4TQ10DXYDG6C1QF6X9Y",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the email domain risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="email_local_part" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the email address local part (the part of the
email address before the @ symbol). If present, this is a value in the
range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410757081243-Email-Risk-Scores#h_01FN6REK9CWDR9V8NQ4WQPTGAD",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the email local part risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property isDeprecated name="email_tenure" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p><strong {...{
            "parentName": "p"
          }}>{`This field has been deprecated.`}</strong>
          {` Please use the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`email_address`}</inlineCode>
          {` risk
factor score instead.`}</p>
      </Property>
      <Property isDeprecated name="ip_tenure" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p><strong {...{
            "parentName": "p"
          }}>{`This field has been deprecated.`}</strong>
          {` Please use `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`risk_score`}</inlineCode>
          {` instead.`}</p>
      </Property>
      <Property name="issuer_id_number" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the particular issuer ID number (IIN) given the
billing location and the history of usage of the IIN on your account and
shop ID. If present, this is a value in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410743622043-Credit-Card-Risk-Scores#h_01FN6RA2R69ZTGTS87VKVQ7F7G",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the IIN risk score for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="order_amount" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the particular order amount for your account and
shop ID. If present, this is a value in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410973728667-Order-Detail-Risk-Scores#h_01FN6RE4TQ10DXYDG6C1QF6X9Y",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the order amount risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="phone_number" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the particular phone number. If present, this is a
value in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410973621019-Billing-and-Shipping-Risk-Scores#h_01FN6R7RDDBA09RJSRKDKSC1NJ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the phone number risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="shipping_address" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the shipping address. If present, this is a value
in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410973621019-Billing-and-Shipping-Risk-Scores#h_01FN6R70AZHQGPME189XTH2SZW",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the shipping address risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="shipping_address_distance_to_ip_location" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the distance between the shipping address and the
location for the given IP address. If present, this is a value in the range
0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410973621019-Billing-and-Shipping-Risk-Scores#h_01FN6R7BQJAVT4KZZTK9Y3HBG6",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the shipping address distance risk score for risk analysis
on our Knowledge Base.`}</a></p>
      </Property>
      <Property name="time_of_day" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`The risk associated with the local time of day of the transaction in the IP
address location. If present, this is a value in the range 0.01 to 99.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4410973728667-Order-Detail-Risk-Scores#h_01FN6REK9CWDR9V8NQ4WQPTGAD",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the time of day risk score for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      