import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.ip_address.risk_reasons} jsonPointer="/ip_address/risk_reasons" linkToSchemaName="Response | IP Address | Risk Reason" name="Response | IP Address | Risk Reasons" mdxType="MinFraudSchema">
      <p>{`This array contains `}
        <a {...{
          "href": "#schema--response--ip-address--risk-reason",
          "parentName": "p"
        }}>{`IP Address Risk Reason objects`}</a>
        {`
identifying the reasons why the IP address received the associated risk.`}</p>
      <p><a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408418812827-IP-Risk-Reasons",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn how to use IP risk reasons for risk analysis on our Knowledge Base.`}</a></p>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      