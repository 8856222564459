import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.email.domain} jsonPointer="/email/domain" name="Response | Email | Domain" service={['insights', 'factors']} mdxType="MinFraudSchema">
      <p>{`This is a sub-object of `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`email`}</inlineCode>
        {` that contains information related to the
domain.`}</p>
      <Property name="first_seen" tags={{
        'Format': 'YYYY-MM-DD',
        'Max Length': 10
      }} mdxType="Property">
        <p>{`A date string (e.g. 2019-01-01) to identify the date an email address
domain was first seen by MaxMind. This is expressed using the ISO 8601 date
format `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`YYYY-MM-DD`}</inlineCode>
          {`. The earliest date that may be returned is January 1, 2019.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408562891803-Email-Risk-Data#h_01FN6V59SHH0J0MRH041K46NE0",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use email first seen data for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      