import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/geoip';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const GeoIpSchema = makeShortcode("GeoIpSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <GeoIpSchema json={responseJson.postal} jsonPointer="/postal" name="Response | Postal" mdxType="GeoIpSchema">
      <p>{`A JSON object containing details about the postal code associated with the IP
address.`}</p>
      <Property name="code" services={['city', 'insights']} mdxType="Property">
        <p>{`A postal code close to the user’s location. For the following countries, we
return partial postal codes with the number of characters indicated below:`}</p>
        <ul>

          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}>{`United States: 5`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}>{`Canada: 3`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}>{`United Kingdom: 2-4`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}>{`Brazil: 5`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}>{`Ireland: 3`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}>{`Japan: 7 (specified for the first 6. The last digit defaults to 1)`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}>{`Netherlands: 4`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}>{`Portugal: 7 (accurate for the first 4. The last 3 often defaults to
`}
              <inlineCode {...{
                "parentName": "p"
              }}>{`-001`}</inlineCode>
              {`)`}</p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}>{`Singapore: 2`}</p>


            <p {...{
              "parentName": "li"
            }}><a {...{
                "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRJSP2B14KSWWJTXKRWZGKJ",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "p"
              }}>{`Learn more about postal code data on our Knowledge
Base.`}</a></p>

          </li>

        </ul>
      </Property>
      <Property name="confidence" services={['insights']} tags={{
        'Min': 0,
        'Max': 100
      }} type="integer" mdxType="Property">
        <p>{`A value from 0-100 representing our confidence that the postal code is
correct.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4414877149467-IP-Geolocation-Data#h_01FRRHZ767N9MJJ21K9CW04WWQ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about confidence factors on our Knowledge
Base.`}</a></p>
      </Property>
    </GeoIpSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      