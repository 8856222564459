import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export { default as Request } from './Request.mdx';
export { default as RequestDevice } from './RequestDevice.mdx';
export { default as RequestEvent } from './RequestEvent.mdx';
export { default as RequestAccount } from './RequestAccount.mdx';
export { default as RequestEmail } from './RequestEmail.mdx';
export { default as RequestBilling } from './RequestBilling.mdx';
export { default as RequestShipping } from './RequestShipping.mdx';
export { default as RequestPayment } from './RequestPayment.mdx';
export { default as RequestCreditCard } from './RequestCreditCard.mdx';
export { default as RequestOrder } from './RequestOrder.mdx';
export { default as RequestShoppingCart } from './RequestShoppingCart.mdx';
export { default as RequestShoppingCartItem } from './RequestShoppingCartItem.mdx';
export { default as RequestCustomInputs } from './RequestCustomInputs.mdx';
export { default as Response } from './Response.mdx';
export { default as ResponseIpAddress } from './ResponseIpAddress.mdx';
export { default as ResponseIpAddressCountry } from './ResponseIpAddressCountry.mdx';
export { default as ResponseIpAddressLocation } from './ResponseIpAddressLocation.mdx';
export { default as ResponseIpAddressRiskReasons } from './ResponseIpAddressRiskReasons.mdx';
export { default as ResponseIpAddressRiskReason } from './ResponseIpAddressRiskReason.mdx';
export { default as ResponseCreditCard } from './ResponseCreditCard';
export { default as ResponseCreditCardIssuer } from './ResponseCreditCardIssuer';
export { default as ResponseDevice } from './ResponseDevice';
export { default as ResponseEmail } from './ResponseEmail';
export { default as ResponseEmailDomain } from './ResponseEmailDomain';
export { default as ResponseShippingAddress } from './ResponseShippingAddress';
export { default as ResponseShippingPhone } from './ResponseShippingPhone';
export { default as ResponseBillingAddress } from './ResponseBillingAddress';
export { default as ResponseBillingPhone } from './ResponseBillingPhone';
export { default as ResponseDisposition } from './ResponseDisposition';
export { default as ResponseRiskReasons } from './ResponseRiskReasons';
export { default as ResponseRiskReason } from './ResponseRiskReason';
export { default as ResponseRiskMultiplierReason } from './ResponseRiskMultiplierReason';
export { default as ResponseSubscores } from './ResponseSubscores';
export { default as ResponseWarnings } from './ResponseWarnings';
export { default as ResponseWarning } from './ResponseWarning';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      