import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.risk_score_reasons[0].reasons[0]} jsonPointer="/risk_score_reasons/{n}/reasons/{n}" name="Response | Risk Score Reason | Multiplier Reason" services={['factors']} mdxType="MinFraudSchema">
      <p>{`This object contains the reason for the multiplier.`}</p>
      <Property name="code" tags={{
        'Enum': null,
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The machine-readable code for the risk reason. Although more
codes may be added in the future, the current codes are:`}</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>{`Code`}</p>
              </th>
              <th>
                <p>{`Reason`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`BROWSER_LANGUAGE`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of the browser user-agent and language associated with the request.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`BUSINESS_ACTIVITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of business activity associated with the request.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`COUNTRY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of the country associated with the request.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`CUSTOMER_ID`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of a customer's activity.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`EMAIL_DOMAIN`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of email domain.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`EMAIL_DOMAIN_NEW`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of newly-sighted email domain.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`EMAIL_ADDRESS_NEW`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of newly-sighted email address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`EMAIL_LOCAL_PART`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of the local part of the email address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`EMAIL_VELOCITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Velocity on email - many requests on same email over short period of time.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`ISSUER_ID_NUMBER_COUNTRY_MISMATCH`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of the country mismatch between IP, billing, shipping and IIN country.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`ISSUER_ID_NUMBER_ON_SHOP_ID`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk of Issuer ID Number for the shop ID.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`ISSUER_ID_NUMBER_LAST_DIGITS_ACTIVITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of many recent requests and previous high-risk requests on the IIN and last digits of the credit card.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`ISSUER_ID_NUMBER_SHOP_ID_VELOCITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk of recent Issuer ID Number activity for the shop ID.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`INTRACOUNTRY_DISTANCE`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk of distance between IP, billing, and shipping location.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`ANONYMOUS_IP`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk due to IP being an Anonymous IP.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`IP_BILLING_POSTAL_VELOCITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Velocity of distinct billing postal code on IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`IP_EMAIL_VELOCITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Velocity of distinct email address on IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`IP_HIGH_RISK_DEVICE`}</inlineCode></p>
              </td>
              <td>
                <p>{`High-risk device sighted on IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`IP_ISSUER_ID_NUMBER_VELOCITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Velocity of distinct IIN on IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`IP_ACTIVITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of IP based on minFraud network activity.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`LANGUAGE`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of browser language.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`MAX_RECENT_EMAIL`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of email address based on past minFraud risk scores on email.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`MAX_RECENT_PHONE`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of phone number based on past minFraud risk scores on phone.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`MAX_RECENT_SHIP`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of email address based on past minFraud risk scores on ship address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`MULTIPLE_CUSTOMER_ID_ON_EMAIL`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of email address having many customer IDs.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`ORDER_AMOUNT`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of the order amount.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`ORG_DISTANCE_RISK`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk of ISP and distance between billing address and IP location.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`PHONE`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of the phone number or related numbers.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`CART`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of shopping cart contents.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`TIME_OF_DAY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk due to local time of day.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`TRANSACTION_REPORT_EMAIL`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk due to transaction reports on the email address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`TRANSACTION_REPORT_IP`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk due to transaction reports on the IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`TRANSACTION_REPORT_PHONE`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk due to transaction reports on the phone number.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`TRANSACTION_REPORT_SHIP`}</inlineCode></p>
              </td>
              <td>
                <p>{`Risk due to transaction reports on the shipping address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`EMAIL_ACTIVITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of the email address based on minFraud network activity.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`PHONE_ACTIVITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of the phone number based on minFraud network activity.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`SHIP_ACTIVITY`}</inlineCode></p>
              </td>
              <td>
                <p>{`Riskiness of ship address based on minFraud network activity.`}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </Property>
      <Property name="reason" mdxType="Property">
        <p>{`The human-readable description of the risk reason.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      