import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.billing_phone} jsonPointer="/billing_phone" name="Response | Billing Phone" services={['insights', 'factors']} mdxType="MinFraudSchema">
      <Property name="country" mdxType="Property">
        <p>{`A two-character `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ISO_3166-1",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`ISO 3166-1`}</a>
          {` country code for the country associated with the billing phone number.`}</p>
      </Property>
      <Property name="network_operator" mdxType="Property">
        <p>{`The name of the original network operator associated with the billing phone number. This field does not reflect phone numbers that have been ported from the original operator to another, nor does it identify `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Mobile_virtual_network_operator",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`mobile virtual network operators`}</a>
          {`.`}</p>
      </Property>
      <Property name="number_type" mdxType="Property">
        <p>{`One of the following values: `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`fixed`}</inlineCode>
          {` or `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`mobile`}</inlineCode>
          {`. Additional values may be added in the future.`}</p>
      </Property>
      <Property name="is_voip" type="boolean" mdxType="Property">
        <p>{`This is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the billing phone number is a Voice over Internet Protocol (VoIP) number allocated by a regulator. It is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if the billing phone number is not a VoIP number allocated by a regulator. The key is only present when a valid billing phone number has been provided and we have data for it.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      