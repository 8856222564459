import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/geoip';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const GeoIpSchema = makeShortcode("GeoIpSchema");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <GeoIpSchema json={responseJson.subdivisions} jsonPointer="/subdivisions" name="Response | Subdivisions" services={['city', 'insights']} mdxType="GeoIpSchema">
      <p>{`An array of `}
        <a {...{
          "href": "#schema--response--subdivision",
          "parentName": "p"
        }}>{`subdivision`}</a>
        {` JSON objects.
Subdivisions are arranged from largest to smallest.`}</p>
    </GeoIpSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      