import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.warnings[0]} jsonPointer="/warnings/{n}" name="Response | Warning" mdxType="MinFraudSchema">
      <Property name="code" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`This value is a machine-readable code identifying the warning. Although more
codes may be added in the future, the current codes are:`}</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>{`Code`}</p>
              </th>
              <th>
                <p>{`Description`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`BILLING_CITY_NOT_FOUND`}</inlineCode></p>
              </td>
              <td>
                <p>{`The billing city could not be found in our database.
This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`BILLING_COUNTRY_MISSING`}</inlineCode></p>
              </td>
              <td>
                <p>{`Billing address information was provided without providing a billing
country. This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`BILLING_COUNTRY_NOT_FOUND`}</inlineCode></p>
              </td>
              <td>
                <p>{`The billing country could not be found in our database.
This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`BILLING_POSTAL_NOT_FOUND`}</inlineCode></p>
              </td>
              <td>
                <p>{`The billing postal could not be found in our database.
This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`BILLING_REGION_NOT_FOUND`}</inlineCode></p>
              </td>
              <td>
                <p>{`The billing region could not be found in our database.
This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`EMAIL_ADDRESS_UNUSABLE`}</inlineCode></p>
              </td>
              <td>
                <p>{`The email address entered is likely incorrect due to an integration issue.
To avoid false positives, it has not been used in scoring. Check how you
are passing your `}
                  <a {...{
                    "href": "/minfraud/api-documentation/requests#schema--request--email",
                    "parentName": "p"
                  }}>{`email address inputs`}</a>
                  {`.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`INPUT_INVALID`}</inlineCode></p>
              </td>
              <td>
                <p>{`The value associated with the key does not meet the required
constraints, e.g., "United States" in a field that requires a
two-letter country code.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`INPUT_UNKNOWN`}</inlineCode></p>
              </td>
              <td>
                <p>{`An unknown key was encountered in the request body.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`IP_ADDRESS_INVALID`}</inlineCode></p>
              </td>
              <td>
                <p>{`The IP address supplied is not a valid IPv4 or IPv6 address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`IP_ADDRESS_NOT_FOUND`}</inlineCode></p>
              </td>
              <td>
                <p>{`The IP address could not be geolocated.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`IP_ADDRESS_RESERVED`}</inlineCode></p>
              </td>
              <td>
                <p>{`The IP address supplied is in a reserved network.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`SHIPPING_CITY_NOT_FOUND`}</inlineCode></p>
              </td>
              <td>
                <p>{`The shipping city could not be found in our database.
This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`SHIPPING_COUNTRY_MISSING`}</inlineCode></p>
              </td>
              <td>
                <p>{`Shipping address information was provided without providing a
shipping country. This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`SHIPPING_COUNTRY_NOT_FOUND`}</inlineCode></p>
              </td>
              <td>
                <p>{`The shipping country could not be found in our database.
This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`SHIPPING_POSTAL_NOT_FOUND`}</inlineCode></p>
              </td>
              <td>
                <p>{`The shipping postal could not be found in our database.
This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><inlineCode {...{
                    "parentName": "p"
                  }}>{`SHIPPING_REGION_NOT_FOUND`}</inlineCode></p>
              </td>
              <td>
                <p>{`The shipping region could not be found in our database.
This may impact our ability to provide accurate distance calculations.`}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </Property>
      <Property name="warning" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`This field provides a human-readable explanation of the warning. The
description may change at any time and should not be matched against.`}</p>
      </Property>
      <Property name="input_pointer" tags={{
        'JSON Pointer': null
      }} mdxType="Property">
        <p>{`A `}
          <a {...{
            "href": "https://tools.ietf.org/html/rfc6901",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`JSON Pointer`}</a>
          {` to the input field
that the warning is associated with. For instance, if the warning was about
the billing city, this would be `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`/billing/city`}</inlineCode>
          {`. If it was for the price in
the second shopping cart item, it would be `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`/shopping_cart/1/price`}</inlineCode></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      