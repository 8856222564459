import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.device} jsonPointer="/device" name="Request | Device" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains information about the device used in the transaction.`}</p>
      <Property name="ip_address" tags={{
        'Format': 'IPv4 or IPv6'
      }} mdxType="Property">
        <p>{`The IP address associated with the device used by the customer in the
transaction. The IP address must be in IPv4 or IPv6 presentation format,
i.e., dotted-quad notation or the IPv6 hexadecimal-colon notation.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5444495353883-Device-Inputs#h_01G0YJ5QHRCS6JBJXJGWPB7DZB",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Get tips for how to pass the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/device/ip_address`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="user_agent" tags={{
        'Max Length': 512
      }} mdxType="Property">
        <p>{`The HTTP `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`User-Agent`}</inlineCode>
          {` header of the browser used in the transaction.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5444495353883-Device-Inputs#h_01G0YJ6YV72W9H65KZ3SEEM6CH",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/device/user_agent`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="accept_language" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The HTTP `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Accept-Language`}</inlineCode>
          {` header of the device used in the transaction.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5444495353883-Device-Inputs#h_01G0YJ6YV72W9H65KZ3SEEM6CH",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/device/accept_language`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="session_age" tags={{
        'Format': 'decimal',
        'Min': 0,
        'Max': '10e13-1'
      }} mdxType="Property">
        <p>{`The number of seconds between the creation of the user's session and the
time of the transaction. Note that `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`session_age`}</inlineCode>
          {` is not the duration of the
current visit, but the time since the start of the first visit.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5444495353883-Device-Inputs#h_01G0YJ77MFVS4XW63W9G2Y1Y65",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/device/session_age`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="session_id" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`An ID that uniquely identifies a visitor's session on the site.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5444495353883-Device-Inputs#h_01G0YJ77MFVS4XW63W9G2Y1Y65",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/device/session_id`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      