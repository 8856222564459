import { FaBook, FaClipboardList, FaShoePrints, FaBroadcastTower, FaQuestionCircle, FaRocket } from 'react-icons/fa';
import * as React from 'react';
export default {
  FaBook,
  FaClipboardList,
  FaShoePrints,
  FaBroadcastTower,
  FaQuestionCircle,
  FaRocket,
  React
};