import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const Footnote = makeShortcode("Footnote");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.shopping_cart[0]} jsonPointer="/shopping_cart/{n}" name="Request | Shopping Cart | Item" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains information about an item from the end-user's shopping
cart associated with the event`}</p>
      <Property name="category" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The category of the item. This can also be a hashed value; see below.`}</p>
      </Property>
      <Property name="item_id" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`Your internal ID for the item. No specific format is required. This can also
be a hashed value; see below.`}</p>
      </Property>
      <Property name="quantity" tags={{
        'Min': 0,
        'Max': '10e13-1'
      }} type="integer" mdxType="Property">
        <p>{`The quantity of the item in the shopping cart. The value must be a whole
number.`}</p>
      </Property>
      <Property name="price" tags={{
        'Format': 'Decimal',
        'Min': 0,
        'Max': '1e14-1'
      }} mdxType="Property">
        <p>{`The per-unit price of this item in the shopping cart. This should use the
same currency as the order currency.`}</p>
      </Property>
      <Footnote mdxType="Footnote">
        <p>{`Passing hashed values for shopping cart items can increase the privacy of
your customers' information while continuing to fulfill the needs for fraud
detection. A suitable hashed value can be produced by using a cryptographic
hash function and a fixed salt. `}
          <strong {...{
            "parentName": "p"
          }}>{`Using a random salt is not recommended`}</strong>
          {`
as that will result in different hashed values for the same plain value,
which would make them ineffective for our fraud detection service. For more
information, see:`}</p>
        <ul>

          <li {...{
            "parentName": "ul"
          }}><a {...{
              "href": "https://cheatsheetseries.owasp.org/cheatsheets/Cryptographic_Storage_Cheat_Sheet.html",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "li"
            }}>{`OWASP Cryptographic Storage Cheat Sheet`}</a></li>


          <li {...{
            "parentName": "ul"
          }}><a {...{
              "href": "https://nvlpubs.nist.gov/nistpubs/FIPS/NIST.FIPS.180-4.pdf",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "li"
            }}>{`NIST FIPS Secure Hash Standard (SHS)`}</a></li>

        </ul>
      </Footnote>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      