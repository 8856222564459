import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.credit_card} jsonPointer="/credit_card" name="Response | Credit Card" services={['insights', 'factors']} mdxType="MinFraudSchema">
      <p>{`This object contains minFraud information related to the credit card. If an
issuer ID number (IIN) was not provided in the request, this object will not
be present in the response.`}</p>
      <Property linkToSchemaName="Response | Credit Card | Issuer" name="issuer" mdxType="Property">
        <p>{`This field contains a JSON object with information relating to the credit card issuer.`}</p>
      </Property>
      <Property name="brand" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The card brand, such as "Visa", "Discover", "American Express", etc.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408734452123-Credit-Card-Risk-Data#h_01FN6TY3X4AHK80QZ85KXX6BZZ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the credit card brand data for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="country" tags={{
        'Max Length': 2
      }} mdxType="Property">
        <p>{`The two letter `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`ISO 3166-1 alpha-2 country code`}</a>
          {`
associated with the location of the majority of customers using this credit
card as determined by their billing address. In cases where the location of
customers is highly mixed, this defaults to the country of the bank issuing
the card.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408734452123-Credit-Card-Risk-Data#h_01FN6TYNBCSRH25VWPQ1CGNN27",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the credit card country data for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property name="is_business" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the issuer ID number is for a business card. It is
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if the issuer ID number is for for a non-business card. The key is
only present when a valid issuer ID number has been provided.`}</p>
      </Property>
      <Property name="is_issued_in_billing_address_country" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the country of the billing address matches the
country of the majority of customers using that IIN. It is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if both
countries are available but do not match. If one or both of the countries
are missing, the key will not be present. In cases where the location of
customers is highly mixed, the match is to the country of the bank issuing
the card.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408737039515-Billing-and-Shipping-Address-Risk-Data#h_01FN6TVQNAXWEBB1T2JW4DNZAX",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the billing address to credit card country matching for
risk analysis on our Knowledge Base.`}</a></p>
      </Property>
      <Property name="is_prepaid" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the issuer ID number is for a prepaid card. It is
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if the issuer ID number is for for a non-prepaid card. The key is
only present when a valid issuer ID number has been provided.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408734452123-Credit-Card-Risk-Data#h_01FN6TXRB1E35Q7Z7BGENRV7MC",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use prepaid card detection for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="is_virtual" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the issuer ID number is for a virtual card. It is
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if the issuer ID number is for a non-virtual card. The key is only
present when a valid issuer ID number has been provided.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408734452123-Credit-Card-Risk-Data#h_01FN6TXRB1E35Q7Z7BGENRV7MC",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use virtual card detection for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="type" tags={{
        'Enum': null
      }} mdxType="Property">
        <p>{`The card’s type. The valid values are:`}</p>
        <ul>

          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`charge`}</inlineCode>
            {` – See `}
            <a {...{
              "href": "https://en.wikipedia.org/wiki/Charge_card",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "li"
            }}>{`Wikipedia`}</a>
            {` for
an explanation of the difference between charge and credit cards.`}</li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`credit`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`debit`}</inlineCode></li>

        </ul>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      