import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p><strong {...{
        "parentName": "p"
      }}>{`We strongly discourage you from using a value from any `}
        <inlineCode {...{
          "parentName": "strong"
        }}>{`*_name`}</inlineCode>
        {` field as a key
in a database or map/dict/hash data structure.`}</strong></p>
    <p>{`These names may change between releases. Instead we recommend using one of the
following:`}</p>
    <table>

      <thead {...{
        "parentName": "table"
      }}>

        <tr {...{
          "parentName": "thead"
        }}>

          <th {...{
            "parentName": "tr"
          }}>{`Data object`}</th>


          <th {...{
            "parentName": "tr"
          }}>{`Recommended key`}</th>

        </tr>

      </thead>


      <tbody {...{
        "parentName": "table"
      }}>

        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`city`}</td>


          <td {...{
            "parentName": "tr"
          }}><inlineCode {...{
              "parentName": "td"
            }}>{`geoname_id`}</inlineCode></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`continent`}</td>


          <td {...{
            "parentName": "tr"
          }}><inlineCode {...{
              "parentName": "td"
            }}>{`continent_code`}</inlineCode></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`country`}</td>


          <td {...{
            "parentName": "tr"
          }}><inlineCode {...{
              "parentName": "td"
            }}>{`country_iso_code`}</inlineCode></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`postal`}</td>


          <td {...{
            "parentName": "tr"
          }}><inlineCode {...{
              "parentName": "td"
            }}>{`postal_code`}</inlineCode></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`subdivisions`}</td>


          <td {...{
            "parentName": "tr"
          }}><inlineCode {...{
              "parentName": "td"
            }}>{`subdivision_{1,2}_iso_code`}</inlineCode></td>

        </tr>

      </tbody>

    </table>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      