import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeSet = makeShortcode("CodeSet");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`The web service may be accessed using `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`curl`}</inlineCode>
      {`, a simple command-line HTTP client.
The `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`-u`}</inlineCode>
      {` flag is used to pass the
`}
      <a {...{
        "href": "https://en.wikipedia.org/wiki/Basic_access_authentication#Client_side",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`HTTP basic authentication header`}</a>
      {`
that provides the web service with your credentials.`}</p>
    <p>{`For the following examples, replace `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`{account_id}`}</inlineCode>
      {` and `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`{license_key}`}</inlineCode>
      {`
(including the brackets) with your
`}
      <a {...{
        "href": "https://www.maxmind.com/en/my_license_key",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`account ID and license key`}</a>
      {`, and
replace `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`{ip_address}`}</inlineCode>
      {` with the IP address you wish to look up.`}</p>
    <CodeSet noReorder="true" mdxType="CodeSet">
      <pre><code {...{
          "className": "language-cli-Country",
          "parentName": "pre"
        }}>{`# Retrieve data for your IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geoip.maxmind.com/geoip/v2.1/country/me?pretty"

# Retrieve data for an arbitrary IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geoip.maxmind.com/geoip/v2.1/country/{ip_address}?pretty"
`}</code></pre>
      <pre><code {...{
          "className": "language-cli-City_Plus",
          "parentName": "pre"
        }}>{`# Retrieve data for your IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geoip.maxmind.com/geoip/v2.1/city/me?pretty"

# Retrieve data for an arbitrary IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geoip.maxmind.com/geoip/v2.1/city/{ip_address}?pretty"
`}</code></pre>
      <pre><code {...{
          "className": "language-cli-Insights",
          "parentName": "pre"
        }}>{`# Retrieve data for your IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geoip.maxmind.com/geoip/v2.1/insights/me?pretty"

# Retrieve data for an arbitrary IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geoip.maxmind.com/geoip/v2.1/insights/{ip_address}?pretty"
`}</code></pre>
      <pre><code {...{
          "className": "language-cli-GeoLite2_Country",
          "parentName": "pre"
        }}>{`# Retrieve data for your IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geolite.info/geoip/v2.1/country/me?pretty"

# Retrieve data for an arbitrary IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geolite.info/geoip/v2.1/country/{ip_address}?pretty"
`}</code></pre>
      <pre><code {...{
          "className": "language-cli-GeoLite2_City",
          "parentName": "pre"
        }}>{`# Retrieve data for your IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geolite.info/geoip/v2.1/city/me?pretty"

# Retrieve data for an arbitrary IP address.
curl -u "{account_id}:{license_key}" \\
  "https://geolite.info/geoip/v2.1/city/{ip_address}?pretty"
`}</code></pre>
    </CodeSet>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      