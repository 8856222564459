import { FaArchive as LegacyIcon, FaCloud as ApiReferenceIcon, FaClipboardList as ReleaseNotesIcon, FaCloud as WebServicesIcon, FaDatabase as DatabasesIcon, FaMapMarkerAlt as GeolocateAnIPIcon, FaPencilAlt, FaQuestionCircle as FaqIcon, FaRocket, FaSync as UpdateDatabasesIcon } from 'react-icons/fa';
import GeoIPOverview from "../../../../content/geoip/_geoip-overview.mdx";
import GeoLiteOverview from "../../../../content/geoip/_geolite-overview.mdx";
import * as React from 'react';
export default {
  LegacyIcon,
  ApiReferenceIcon,
  ReleaseNotesIcon,
  WebServicesIcon,
  DatabasesIcon,
  GeolocateAnIPIcon,
  FaPencilAlt,
  FaqIcon,
  FaRocket,
  UpdateDatabasesIcon,
  GeoIPOverview,
  GeoLiteOverview,
  React
};