import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.risk_score_reasons} jsonPointer="/risk_score_reasons" name="Response | Risk Score Reasons" services={['factors']} mdxType="MinFraudSchema">
      <Alert type="info" mdxType="Alert">
        <p>{`The risk reasons output codes and reasons are currently in beta and are subject to change.
These outputs are being actively developed and tested, and may undergo modifications that could impact their structure, format, and content.
While we strive to maintain stability, we recommend that you use these beta outputs with caution and avoid relying on them for critical applications.
Your feedback is valuable and will help us improve the final release.`}</p>
      </Alert>
      <p>{`This array contains
`}
        <a {...{
          "href": "#schema--response--risk-score-reason",
          "parentName": "p"
        }}>{`risk score reason objects`}</a>
        {`. Risk score
reasons are usually only returned for medium to high risk transactions. If there
were no significant changes to the risk score due to these reasons, then this
array will not be present in the response.`}</p>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      