import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Alert type="info" mdxType="Alert">
      <p>{`The Sandbox will return geolocation and IP intelligence data, but this data is
not accurate and should not be used to evaluate the efficacy of the minFraud
scoring engine, or for geolocation or IP intelligence. It is designed for
testing purposes only.`}</p>
    </Alert>
    <p>{`There are a limited number of IP addresses in the test database for the GeoIP
web services on the Sandbox. The Sandbox versions of the GeoIP web services use
`}
      <a {...{
        "href": "https://github.com/maxmind/MaxMind-DB/blob/main/source-data/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`special test data that is published to GitHub`}</a>
      {`.`}</p>
    <p>{`If you attempt to submit an IP address that doesn't exist in the test data, you
will receive an error stating that the IP address does not exist in our
database.`}</p>
    <p>{`The test data is displayed in JSON format, and would be the expected response
for a query using the listed IP address.
`}
      <a {...{
        "href": "https://support.maxmind.com/hc/en-us/articles/8610782760731-Sandbox-for-the-GeoIP-Web-Services#h_01GC7FJGRWC116ZCDPAJP09V9M",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`Learn more about working with our GeoIP test data on our Knowledge Base.`}</a></p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      