import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.order} jsonPointer="/order" name="Request | Order" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains information about the order associated with the event.`}</p>
      <Property name="amount" tags={{
        'Format': 'decimal',
        'Min': 0,
        'Max': '1e14 – 1'
      }} mdxType="Property">
        <p>{`The total order amount for the transaction before taxes and discounts.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452293435675-Order-and-Shopping-Cart-Inputs#h_01G0Z50FDAR22FR731C0CD2AE4",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/order/amount`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="currency" tags={{
        'Max Length': 3
      }} mdxType="Property">
        <p>{`The `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ISO_4217",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`ISO 4217 currency code`}</a>
          {` for the
currency used in the transaction.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452293435675-Order-and-Shopping-Cart-Inputs#h_01G0Z50FDAR22FR731C0CD2AE4",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/order/currency`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="discount_code" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The discount code applied to the transaction. If multiple discount codes
were used, please separate them with a comma.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452293435675-Order-and-Shopping-Cart-Inputs#h_01G0Z50ZVZ2GMAF4DG4N0FA73W",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/order/discount_code`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="affiliate_id" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The ID of the affiliate where the order is coming from. No specific format
is required.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452293435675-Order-and-Shopping-Cart-Inputs#h_01G0Z50Q0MRXQ5R52EF34E6G7J",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/order/affiliate_id`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="subaffiliate_id" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The ID of the sub-affiliate where the order is coming from. No specific
format is required.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452293435675-Order-and-Shopping-Cart-Inputs#h_01G0Z50Q0MRXQ5R52EF34E6G7J",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/order/subaffiliate_id`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="referrer_uri" tags={{
        'Max Length': 1024
      }} mdxType="Property">
        <p>{`The URI of the referring site for this order. Needs to be absolute and have
a URI scheme such as `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`https://`}</inlineCode>
          {`.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452293435675-Order-and-Shopping-Cart-Inputs#h_01G0Z50Q0MRXQ5R52EF34E6G7J",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/order/referrer_uri`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="is_gift" mdxType="Property">
        <p>{`Whether order was marked as a gift by the purchaser.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452293435675-Order-and-Shopping-Cart-Inputs#h_01G0Z517AZEMSN8V3GWTV131S6",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/order/is_gift`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="has_gift_message" mdxType="Property">
        <p>{`Whether the purchaser included a gift message.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5452293435675-Order-and-Shopping-Cart-Inputs#h_01G0Z517AZEMSN8V3GWTV131S6",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/order/has_gift_message`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      