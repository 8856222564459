import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/geoip';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const GeoIpSchema = makeShortcode("GeoIpSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <GeoIpSchema json={responseJson} jsonPointer="/" name="Response" services="*" mdxType="GeoIpSchema">
      <p>{`All services return data as a JSON document. The document that is returned
always consists of an object (aka map or hash). Each key in the object in turn
maps to an object or an array of objects.`}</p>
      <p>{`The exact set of top-level keys varies based on the particular GeoIP2 web
service you are using. If a key maps to an undefined or empty value, it is not
included in the JSON object. This applies both to top-level keys and the objects
they map to.`}</p>
      <p>{`The data returned in the document will be in UTF-8 encoding.`}</p>
      <Property linkToSchemaName={'Response | City'} name="city" services={['city', 'insights']} mdxType="Property">
        <p>{`A JSON object containing details about the city associated with the IP
address.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Continent'} name="continent" mdxType="Property">
        <p>{`A JSON object containing information about the continent associated with the
IP address.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Country'} name="country" mdxType="Property">
        <p>{`A JSON object containing details about the country where MaxMind believes
the end user is located.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Location'} name="location" services={['city', 'insights']} mdxType="Property">
        <p>{`A JSON object containing specific details about the location associated with
the IP address.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Postal'} name="postal" services={['city', 'insights']} mdxType="Property">
        <p>{`A JSON object containing details about the postal code associated with the
IP address.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Registered Country'} name="registered_country" mdxType="Property">
        <p>{`A JSON object containing details about the country in which the ISP has
registered the IP address.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Represented Country'} name="represented_country" mdxType="Property">
        <p>{`A JSON object containing details about the country which is represented by
users of the IP address. For instance, the country represented by an
overseas military base.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Subdivisions'} name="subdivisions" services={['city', 'insights']} mdxType="Property">
        <p>{`An array of JSON objects. Each of these objects contains details about a
subdivision of the country in which the IP address resides. Subdivisions are
arranged from largest to smallest.`}</p>
        <p>{`For instance, the response for Oxford in the United Kingdom would have an
object for England as the first element in `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`subdivisions`}</inlineCode>
          {` array and an
object for Oxfordshire as the second element. The `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`subdivisions`}</inlineCode>
          {` array for
Minneapolis in the United States will have a single object for Minnesota.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | Traits'} name="traits" mdxType="Property">
        <p>{`A JSON object containing general traits associated with the IP address.`}</p>
      </Property>
      <Property linkToSchemaName={'Response | MaxMind'} name="maxmind" mdxType="Property">
        <p>{`A JSON object containing information related to your MaxMind account.`}</p>
      </Property>
    </GeoIpSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      