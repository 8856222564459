import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.credit_card.issuer} jsonPointer="/credit_card/issuer" name="Response | Credit Card | Issuer" services={['insights', 'factors']} mdxType="MinFraudSchema">
      <p>{`This is a sub-object of `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`credit_card`}</inlineCode>
        {` that contains information related to the
issuer of the card.`}</p>
      <Property name="name" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`This field contains a JSON object with information relating to the credit card issuer.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408734452123-Credit-Card-Risk-Data#h_01FN6TY3X4AHK80QZ85KXX6BZZ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the credit card issuer name for risk analysis on our
Knowledge Base.`}</a></p>
      </Property>
      <Property isDeprecated name="matches_provided_name" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the name matches the name provided in the request
for the card issuer. It is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if the name does not match. The field is
not included if either no name or issuer ID number (IIN) is provided in the
request or if MaxMind does not have a name associated with the IIN.`}</p>
      </Property>
      <Property name="phone_number" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The phone number of the bank which issued the credit card. In some cases the
phone number we return may be out of date.`}</p>
      </Property>
      <Property isDeprecated name="matches_provided_phone_number" mdxType="Property">
        <p>{`This field is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the phone number matches the number provided in the
request for the card issuer. It is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if the number does not match. The
field is not included if either no phone number or issuer ID number (IIN) is
provided in the request or if MaxMind does not have a phone number
associated with the IIN.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      