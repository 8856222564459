import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.ip_address} jsonPointer="/ip_address" name="Response | IP Address" mdxType="MinFraudSchema">
      <p>{`For minFraud Score, this object only contains the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`risk`}</inlineCode>
        {` for the IP address.
For minFraud Insights and Factors, the object is the
`}
        <a {...{
          "href": "/geoip/docs/web-services/responses/#geoip2-insights-body-example",
          "parentName": "p"
        }}>{`GeoIP2 Insights response body`}</a>
        {`
with four modifications:`}</p>
      <ol>

        <li {...{
          "parentName": "ol"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`risk`}</inlineCode>
            {` has been added directly to the `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`ip_address`}</inlineCode>
            {` object`}</p>

        </li>


        <li {...{
          "parentName": "ol"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`local_time`}</inlineCode>
            {` has been added to the `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`location`}</inlineCode>
            {` sub-object`}</p>

        </li>


        <li {...{
          "parentName": "ol"
        }}>

          <p {...{
            "parentName": "li"
          }}>{`The `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`maxmind`}</inlineCode>
            {` object is not present. See below for descriptions.`}</p>

        </li>


        <li {...{
          "parentName": "ol"
        }}>

          <p {...{
            "parentName": "li"
          }}>{`minFraud Insights and Factors return the following anonymous IP outputs:`}</p>


          <ul {...{
            "parentName": "li"
          }}>

            <li {...{
              "parentName": "ul"
            }}><inlineCode {...{
                "parentName": "li"
              }}>{`is_anonymous`}</inlineCode></li>


            <li {...{
              "parentName": "ul"
            }}><inlineCode {...{
                "parentName": "li"
              }}>{`is_anonymous_vpn`}</inlineCode></li>


            <li {...{
              "parentName": "ul"
            }}><inlineCode {...{
                "parentName": "li"
              }}>{`is_hosting_provider`}</inlineCode></li>


            <li {...{
              "parentName": "ul"
            }}><inlineCode {...{
                "parentName": "li"
              }}>{`is_public_proxy`}</inlineCode></li>


            <li {...{
              "parentName": "ul"
            }}><inlineCode {...{
                "parentName": "li"
              }}>{`is_residential_proxy`}</inlineCode></li>


            <li {...{
              "parentName": "ul"
            }}><inlineCode {...{
                "parentName": "li"
              }}>{`is_tor_exit_node`}</inlineCode></li>

          </ul>

        </li>

      </ol>
      <p>{`See the
`}
        <a {...{
          "href": "/geoip/docs/web-services/responses/#geoip2-insights-body-example",
          "parentName": "p"
        }}>{`GeoIP2 Insights response body`}</a>
        {`
for more information.`}</p>
      <Property name="risk" services="*" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`This field contains the risk associated with the IP address. The value
ranges from 0.01 to 99. A higher score indicates a higher risk.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408382525851-Device-Risk-Scores#h_01FN6HE00G80Y22P4WSXJ81C6Y",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the IP risk score on our Knowledge
Base.`}</a></p>
      </Property>
      <Property linkToSchemaName="Response | IP Address | Country" name="country" services={['insights', 'factors']} mdxType="Property">
        <p>{`This object contains country-level geolocation data associated with the IP
address associated with the event.`}</p>
      </Property>
      <Property linkToSchemaName="Response | IP Address | Location" name="location" services={['insights', 'factors']} mdxType="Property">
        <p>{`This object contains city-level geolocation data associated with the IP
address associated with the event.`}</p>
      </Property>
      <Property linkToSchemaName="Response | IP Address | Risk Reasons" name="risk_reasons" services={['insights', 'factors']} mdxType="Property">
        <p>{`This array contains `}
          <a {...{
            "href": "#schema--response--ip-address--risk-reason",
            "parentName": "p"
          }}>{`IP Address Risk Reason objects`}</a>
          {`
identifying the reasons why the IP address received the associated risk.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408418812827-IP-Risk-Reasons",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use IP risk reasons for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      