import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`The following APIs are developed and maintained by MaxMind. See
`}
      <a {...{
        "href": "/contribute",
        "parentName": "p"
      }}>{`our guide on developing for the community`}</a>
      {` if you have questions
about creating or sharing your own unofficial clients or integrations.`}</p>
    <table>

      <thead {...{
        "parentName": "table"
      }}>

        <tr {...{
          "parentName": "thead"
        }}>

          <th {...{
            "parentName": "tr"
          }}>{`Language or Framework`}</th>


          <th {...{
            "parentName": "tr"
          }}>{`Package Repository`}</th>


          <th {...{
            "parentName": "tr"
          }}>{`Documentation`}</th>


          <th {...{
            "parentName": "tr"
          }}>{`Version Control`}</th>

        </tr>

      </thead>


      <tbody {...{
        "parentName": "table"
      }}>

        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`.NET (C#)`}</td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://www.nuget.org/packages/MaxMind.MinFraud",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`NuGet`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://maxmind.github.io/minfraud-api-dotnet",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub Pages`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://github.com/maxmind/minfraud-api-dotnet",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub`}</a></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`Java`}</td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://search.maven.org/#search%7Cga%7C1%7Cg%3A%22com.maxmind.minfraud%22",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`Maven Central`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://maxmind.github.io/minfraud-api-java",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub Pages`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://github.com/maxmind/minfraud-api-java",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub`}</a></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`Node.js`}</td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://npmjs.com/package/@maxmind/minfraud-api-node",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`NPM`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://maxmind.github.io/minfraud-api-node/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub Pages`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://github.com/maxmind/minfraud-api-node",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub`}</a></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`Perl (deprecated)`}</td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://metacpan.org/release/WebService-MinFraud",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`metacpan`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://metacpan.org/pod/WebService::MinFraud",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`metacpan`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://github.com/maxmind/minfraud-api-perl",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub`}</a></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`PHP`}</td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://packagist.org/packages/maxmind/minfraud",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`Packagist`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://maxmind.github.io/minfraud-api-php",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub Pages`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://github.com/maxmind/minfraud-api-php",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub`}</a></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`Python`}</td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://pypi.python.org/pypi/minfraud",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`PyPI`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://minfraud.readthedocs.io/en/latest/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`Read the Docs`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://github.com/maxmind/minfraud-api-python",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub`}</a></td>

        </tr>


        <tr {...{
          "parentName": "tbody"
        }}>

          <td {...{
            "parentName": "tr"
          }}>{`Ruby`}</td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://rubygems.org/gems/minfraud",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`RubyGems.org`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://www.rubydoc.info/gems/minfraud/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`RubyDoc.info`}</a></td>


          <td {...{
            "parentName": "tr"
          }}><a {...{
              "href": "https://github.com/maxmind/minfraud-api-ruby",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer",
              "parentName": "td"
            }}>{`GitHub`}</a></td>

        </tr>

      </tbody>

    </table>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      