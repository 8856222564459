import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/geoip';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const GeoIpSchema = makeShortcode("GeoIpSchema");
const Property = makeShortcode("Property");
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <GeoIpSchema json={responseJson.traits} jsonPointer="/traits" name="Response | Traits" mdxType="GeoIpSchema">
      <p>{`A JSON object containing general traits associated with the IP address.`}</p>
      <Property name="autonomous_system_number" services={['city', 'insights']} type="integer" mdxType="Property">
        <p>{`The `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Autonomous_system_(Internet)",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`autonomous system number`}</a>
          {`
associated with the IP address.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN989WWSE56YD6AV4QSPSWDW",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about autonomous system data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="autonomous_system_organization" services={['city', 'insights']} mdxType="Property">
        <p>{`The organization associated with the registered
`}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Autonomous_system_(Internet)",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`autonomous system number`}</a>
          {`
for the IP address.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN989WWSE56YD6AV4QSPSWDW",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about autonomous system data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="connection_type" services={['city', 'insights']} mdxType="Property">
        <p>{`One of the following values: `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Cable/DSL`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Cellular`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Corporate`}</inlineCode>
          {`, or
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Satellite`}</inlineCode>
          {`. Additional values may be added in the future.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`This field is not present in the GeoLite2 City web service.`}</p>
        </Alert>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN988THBX8RVERNTZ12BY2EC",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about connection type data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="domain" services={['city', 'insights']} mdxType="Property">
        <p>{`The second level domain associated with the IP address. This will be
something like “example.com” or “example.co.uk”, not “foo.example.com”.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`This field is not present in the GeoLite2 City web service.`}</p>
        </Alert>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN98A5BNTS0GGWTD2QA4AHXN",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about domain name data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="ip_address" services="*" mdxType="Property">
        <p>{`The requested IP address.`}</p>
      </Property>
      <Property name="is_anonymous" services={['insights']} mdxType="Property">
        <p>{`This is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the IP address belongs to any sort of anonymous network.
Otherwise, the key is not included in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`traits`}</inlineCode>
          {` object.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208507163-Anonymizer-and-Proxy-Data#h_01FN9BBGV3ZG3TC0357Q9Y07C6",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about anonymizer and proxy detection on our Knowledge
Base.`}</a></p>
      </Property>
      <Property isDeprecated name="is_anonymous_proxy" services="*" mdxType="Property">
        <p>{`Consider using one of our anonymizer service outputs, such as `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`is_anonymous`}</inlineCode>
          {`
and `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`is_anonymous_vpn`}</inlineCode>
          {`. These anonymizing service outputs are available in
the `}
          <a {...{
            "href": "https://www.maxmind.com/en/geoip2-anonymous-ip-database",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GeoIP2 Anonymous IP database`}</a>
          {`
and the `}
          <a {...{
            "href": "https://www.maxmind.com/en/geoip2-precision-insights",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GeoIP2 Insights web services`}</a>
          {`.`}</p>
      </Property>
      <Property name="is_anonymous_vpn" services={['insights']} mdxType="Property">
        <p>{`This is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the IP address is registered to an anonymous VPN provider.
Otherwise, the key is not included in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`traits`}</inlineCode>
          {` object.`}</p>
        <p>{`If a VPN provider does not register subnets under names associated with
them, we will likely only flag their IP ranges using the
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`is_hosting_provider`}</inlineCode>
          {` flag.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208507163#h_01G1EDVJKNQY02XXFRM31N7SS2",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about VPNs on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="is_anycast" services="*" mdxType="Property">
        <p>{`This is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the IP address belongs to an `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Anycast",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`anycast network`}</a>
          {`.`}</p>
      </Property>
      <Property name="is_hosting_provider" services={['insights']} mdxType="Property">
        <p>{`This is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the IP address belongs to a hosting or VPN provider
(see description of `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`is_anonymous_vpn`}</inlineCode>
          {` flag). Otherwise, the key is not
included in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`traits`}</inlineCode>
          {` object.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208507163#h_01G1EDVXR9RQFMCY6SAWJM4YH0",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about hosting providers used for anonymizing on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="is_public_proxy" services={['insights']} mdxType="Property">
        <p>{`This is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the IP address belongs to a public proxy. Otherwise, the
key is not included in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`traits`}</inlineCode>
          {` object.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208507163#h_01G1EDW5RZQCD4X4A76908DJ6Z",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about public proxies on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="is_residential_proxy" services={['insights']} mdxType="Property">
        <p>{`This is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the IP address is on a suspected anonymizing network and
belongs to a residential ISP (does not include peer-to-peer proxy IPs).
Otherwise, the key is not included in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`traits`}</inlineCode>
          {` object.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208507163#h_01G1EDWEW3HAJT97P8EAZFQZMZ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about residential proxies on our Knowledge
Base.`}</a></p>
      </Property>
      <Property isDeprecated name="is_satellite_provider" services="*" mdxType="Property" />
      <Property name="is_tor_exit_node" services={['insights']} mdxType="Property">
        <p>{`This is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if the IP address is a Tor exit node. Otherwise, the key is
not included in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`traits`}</inlineCode>
          {` object.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208507163#h_01G1EDWR1VQR8A0NB3R8WXT8QF",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about Tor exit nodes on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="isp" services={['city', 'insights']} mdxType="Property">
        <p>{`The name of the ISP associated with the IP address.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`This field is not present in the GeoLite2 City web service.`}</p>
        </Alert>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN989KHXR7TGXPB5T2DK0Q77",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about ISP data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="mobile_country_code" services={['city', 'insights']} mdxType="Property">
        <p>{`The `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Mobile_country_code",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`mobile country code (MCC)`}</a>
          {` associated with the IP address and ISP.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`This field is not present in the GeoLite2 City web service.`}</p>
        </Alert>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FT6Y6ANRH9GWYXE78B4RXAEX",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about mobile country code data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="mobile_network_code" services={['city', 'insights']} mdxType="Property">
        <p>{`The `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Mobile_country_code",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`mobile network code (MNC)`}</a>
          {` associated with the IP address and ISP.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`This field is not present in the GeoLite2 City web service.`}</p>
        </Alert>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FT6Y6ANRH9GWYXE78B4RXAEX",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about mobile network code data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="network" services="*" mdxType="Property">
        <p>{`The network in `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Classless_Inter-Domain_Routing#CIDR_notation",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`CIDR notation`}</a>
          {`
associated with the record. In particular, this is the largest network where
all of the fields besides `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`ip_address`}</inlineCode>
          {` have the same value.`}</p>
      </Property>
      <Property name="organization" services={['city', 'insights']} mdxType="Property">
        <p>{`The name of the organization associated with the IP address.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`This field is not present in the GeoLite2 City web service.`}</p>
        </Alert>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN989KHXR7TGXPB5T2DK0Q77",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about organization data on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="static_ip_score" services={['insights']} tags={{
        'Format': 'Decimal',
        'Min': 0,
        'Max': 99.99
      }} mdxType="Property">
        <p>{`An indicator of how static or dynamic an IP address is. The value ranges
from 0 to 99.99 with higher values meaning a greater static association. For
example, many IP addresses with a `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`user_type`}</inlineCode>
          {` of `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`cellular`}</inlineCode>
          {` have a score
under one. Broadband IPs that don’t change very often typically have a score
above thirty.`}</p>
        <p>{`This indicator can be useful for deciding whether an IP address represents
the same user over time.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208479131-User-Context-Data#h_01FN9BSX7X351J8PV0WCH0E88F",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the static IP score on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="user_count" services={['insights']} type="integer" mdxType="Property">
        <p>{`The estimated number of users sharing the IP/network during the past 24
hours. For IPv4, the count is for the individual IP. For IPv6, the count
is for the /64 network.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408208479131-User-Context-Data#h_01FN9BT5PC591YRNBFZGHDYNS6",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the user count on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="user_type" services={['insights']} mdxType="Property">
        <p>{`The user type associated with the IP address. This will be one of the
following values:`}</p>
        <ul>

          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`business`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`cafe`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`cellular`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`college`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`consumer_privacy_network`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`content_delivery_network`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`government`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`hosting`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`library`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`military`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`residential`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`router`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`school`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`search_engine_spider`}</inlineCode></p>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`traveler`}</inlineCode></p>


            <p {...{
              "parentName": "li"
            }}><a {...{
                "href": "https://support.maxmind.com/hc/en-us/articles/4408208479131-User-Context-Data#h_01FN9BTGFQVP41YNPDGM454T2T",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "p"
              }}>{`Learn more about the user type on our Knowledge
Base.`}</a></p>

          </li>

        </ul>
      </Property>
    </GeoIpSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      