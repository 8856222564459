import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.device} jsonPointer="/device" name="Response | Device" services={['insights', 'factors']} mdxType="MinFraudSchema">
      <p>{`This object contains information about the device that MaxMind believes is
associated with the IP address passed in the request.`}</p>
      <Property name="confidence" tags={{
        'Format': 'Decimal',
        'Min': 0.01,
        'Max': 99
      }} mdxType="Property">
        <p>{`A number from 0.01 to 99 representing the confidence that the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`/device/id`}</inlineCode>
          {`
refers to a unique device as opposed to a cluster of similar devices. A
confidence of 0.01 indicates very low confidence that the device is unique,
whereas 99 indicates very high confidence.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408634894107-Device-Risk-Data#h_01FN6V1ANY9XA76Z69HG2DZ5TJ",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use device confidence for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="id" tags={{
        'Format': 'UUID'
      }} mdxType="Property">
        <p>{`A UUID that MaxMind uses for the device associated with this IP address.
This is only available if you are using the
`}
          <a {...{
            "href": "/minfraud/api-documentation#device-tracking-add-on",
            "parentName": "p"
          }}>{`Device Tracking Add-on`}</a>
          {`.`}</p>
      </Property>
      <Property name="last_seen" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The date and time of the last sighting of the device. The value is formatted
according to `}
          <a {...{
            "href": "https://tools.ietf.org/html/rfc3339",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`RFC 3339`}</a>
          {`.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408634894107-Device-Risk-Data#h_01FN6V29YM8FA1A48G0N2G7VRW",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use the last sighting data for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="local_time" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The local date and time of the transaction in the time zone of the device.
This is determined by using the UTC offset associated with the device.
The value is formatted according to `}
          <a {...{
            "href": "https://tools.ietf.org/html/rfc3339",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`RFC 3339`}</a>
          {`.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408634894107-Device-Risk-Data#h_01FN6V22JSGD7JP7Y3C9YBERHE",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn how to use local time data for risk analysis on our Knowledge
Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      