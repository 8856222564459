import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Alert type="warning" mdxType="Alert">
      <p><strong {...{
          "parentName": "p"
        }}>{`Data Privacy`}</strong></p>
      <p>{`To comply with data privacy regulations, please monitor our
`}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/do-not-sell-requests",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Do Not Sell My Personal Information Requests`}</a>
        {`
page (login required) for IP addresses and networks that should not be used for
advertising or marketing purposes. You can automate retrieval of this list using
our `}
        <a {...{
          "href": "/geoip/privacy-exclusions-api",
          "parentName": "p"
        }}>{`privacy exclusions API`}</a>
        {`.`}</p>
    </Alert>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      