import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.payment} jsonPointer="/payment" name="Request | Payment" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains information from and about the payment process that
was used for the event.`}</p>
      <Property name="processor" tags={{
        Enum: null
      }} mdxType="Property">
        <p>{`The payment processor used for the transaction. The valid values are:`}</p>
        <ul>

          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`adyen`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`affirm`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`afterpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`altapay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`amazon_payments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`american_express_payment_gateway`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`apple_pay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`aps_payments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`authorizenet`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`balanced`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`beanstream`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`bluepay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`bluesnap`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`boacompra`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`boku`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`bpoint`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`braintree`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`cardknox`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`cardpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`cashfree`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`ccavenue`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`ccnow`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`cetelem`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`chase_paymentech`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`checkout_com`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`cielo`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`collector`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`commdoo`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`compropago`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`concept_payments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`conekta`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`coregateway`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`creditguard`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`credorax`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`ct_payments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`cuentadigital`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`curopayments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`cybersource`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`dalenys`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`dalpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`datacap`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`datacash`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`dibs`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`digital_river`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`dlocal`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`dotpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`ebs`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`ecomm365`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`ecommpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`elavon`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`emerchantpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`epay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`eprocessing_network`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`epx`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`eway`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`exact`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`first_atlantic_commerce`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`first_data`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`fiserv`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`g2a_pay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`global_payments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`gocardless`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`google_pay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`heartland`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`hipay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`ingenico`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`interac`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`internetsecure`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`intuit_quickbooks_payments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`iugu`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`klarna`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`komoju`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`lemon_way`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`mastercard_payment_gateway`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`mercadopago`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`mercanet`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`merchant_esolutions`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`mirjeh`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`mollie`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`moneris_solutions`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`neopay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`neosurf`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`nmi`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`oceanpayment`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`oney`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`onpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`openbucks`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`openpaymx`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`optimal_payments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`orangepay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`other`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`pacnet_services`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payconex`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payeezy`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payfast`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paygate`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paylike`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payment_express`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paymentwall`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payone`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paypal`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payplus`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paysafecard`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paysera`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paystation`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paytm`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paytrace`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`paytrail`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payture`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payulatam`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payvision`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payu`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payway`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`payza`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`pinpayments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`placetopay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`posconnect`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`princeton_payment_solutions`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`psigate`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`pxp_financial`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`qiwi`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`quickpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`raberil`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`razorpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`rede`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`redpagos`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`rewardspay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`safecharge`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`sagepay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`securetrading`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`shopify_payments`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`simplify_commerce`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`skrill`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`smartcoin`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`smartdebit`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`solidtrust_pay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`sps_decidir`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`stripe`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`synapsefi`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`systempay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`telerecargas`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`towah`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`transact_pro`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`trustly`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`trustpay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`tsys`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`usa_epay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`vantiv`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`verepay`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`vericheck`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`vindicia`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`virtual_card_services`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`vme`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`vpos`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`windcave`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`wirecard`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`worldpay`}</inlineCode></li>

        </ul>
        <p>{`If your payment processor is missing from this list, please contact
`}
          <a {...{
            "href": "mailto:support@maxmind.com",
            "parentName": "p"
          }}>{`support@maxmind.com`}</a>
          {`.`}</p>
      </Property>
      <Property name="was_authorized" mdxType="Property">
        <p>{`The authorization outcome from the payment processor. If the transaction has
not yet been approved or denied, do not include this field.`}</p>
      </Property>
      <Property name="decline_code" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The decline code as provided by your payment processor. If the transaction
was not declined, do not include this field.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      