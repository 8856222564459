export default {
  account: {
    user_id: '3132',
    username_md5: '570a90bfbf8c7eab5dc5d4e26832d5b1',
  },
  billing: {
    address: '400 Blake St.',
    address_2: 'Suite 5',
    city: 'New Haven',
    company: 'Big Corp.',
    country: 'US',
    first_name: 'John',
    last_name: 'Doe',
    phone_country_code: '1',
    phone_number: '203-000-0000',
    postal: '06511',
    region: 'CT',
  },
  credit_card: {
    avs_result: 'Y',
    bank_name: 'Bank of America',
    bank_phone_country_code: '1',
    bank_phone_number: '800-342-1232',
    country: 'US',
    cvv_result: 'N',
    issuer_id_number: '323132',
    last_digits: '7643',
    token: 'OQRST14PLQ98323',
    was_3d_secure_successful: true,
  },
  custom_inputs: {
    a_custom_input_key: 'NSC0083121',
    another_custom_input_key: false,
  },
  device: {
    accept_language: 'en-US,en;q=0.8',
    ip_address: '2001:db8::ff00:42:8329',
    session_age: 3600.5,
    session_id: 'c2ffa1b7-f5c5-4702-beb2-4254794fe391',
    user_agent: 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.89 Safari/537.36',
  },
  email: {
    address: '977577b140bfb7c516e4746204fbdb01',
    domain: 'maxmind.com',
  },
  event: {
    shop_id: 's2123',
    time: '2012-04-12T23:20:50.52Z',
    transaction_id: 'txn3134133',
    type: 'purchase',
  },
  order: {
    affiliate_id: 'af12',
    amount: 323.21,
    currency: 'USD',
    discount_code: 'FIRST',
    has_gift_message: false,
    is_gift: true,
    referrer_uri: 'http://www.google.com/',
    subaffiliate_id: 'saf42',
  },
  payment: {
    decline_code: 'card_declined',
    processor: 'stripe',
    was_authorized: false,
  },
  shipping: {
    address: '82 Wall St.',
    address_2: '#1',
    city: 'New Haven',
    company: 'Smaller, Inc.',
    country: 'US',
    delivery_speed: 'same_day',
    first_name: 'Jane',
    last_name: 'Doe',
    phone_country_code: '1',
    phone_number: '203-000-0000',
    postal: '06515',
    region: 'CT',
  },
  shopping_cart: [
    {
      category: 'pets',
      item_id: 'ad23232',
      price: 20.43,
      quantity: 2,
    },
    {
      category: 'beauty',
      item_id: 'bst112',
      price: 100.00,
      quantity: 1,
    },
  ],
};
