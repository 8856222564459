exports.components = {
  "component---content-contribute-mdx": () => import("./../../../content/contribute.mdx" /* webpackChunkName: "component---content-contribute-mdx" */),
  "component---content-geoip-docs-databases-anonymous-ip-mdx": () => import("./../../../content/geoip/docs/databases/anonymous-ip.mdx" /* webpackChunkName: "component---content-geoip-docs-databases-anonymous-ip-mdx" */),
  "component---content-geoip-docs-databases-asn-mdx": () => import("./../../../content/geoip/docs/databases/asn.mdx" /* webpackChunkName: "component---content-geoip-docs-databases-asn-mdx" */),
  "component---content-geoip-docs-databases-city-and-country-mdx": () => import("./../../../content/geoip/docs/databases/city-and-country.mdx" /* webpackChunkName: "component---content-geoip-docs-databases-city-and-country-mdx" */),
  "component---content-geoip-docs-databases-connection-type-mdx": () => import("./../../../content/geoip/docs/databases/connection-type.mdx" /* webpackChunkName: "component---content-geoip-docs-databases-connection-type-mdx" */),
  "component---content-geoip-docs-databases-domain-mdx": () => import("./../../../content/geoip/docs/databases/domain.mdx" /* webpackChunkName: "component---content-geoip-docs-databases-domain-mdx" */),
  "component---content-geoip-docs-databases-enterprise-mdx": () => import("./../../../content/geoip/docs/databases/enterprise.mdx" /* webpackChunkName: "component---content-geoip-docs-databases-enterprise-mdx" */),
  "component---content-geoip-docs-databases-isp-mdx": () => import("./../../../content/geoip/docs/databases/isp.mdx" /* webpackChunkName: "component---content-geoip-docs-databases-isp-mdx" */),
  "component---content-geoip-docs-databases-mdx": () => import("./../../../content/geoip/docs/databases.mdx" /* webpackChunkName: "component---content-geoip-docs-databases-mdx" */),
  "component---content-geoip-docs-web-services-legacy-mdx": () => import("./../../../content/geoip/docs/web-services/legacy.mdx" /* webpackChunkName: "component---content-geoip-docs-web-services-legacy-mdx" */),
  "component---content-geoip-docs-web-services-mdx": () => import("./../../../content/geoip/docs/web-services.mdx" /* webpackChunkName: "component---content-geoip-docs-web-services-mdx" */),
  "component---content-geoip-docs-web-services-requests-mdx": () => import("./../../../content/geoip/docs/web-services/requests.mdx" /* webpackChunkName: "component---content-geoip-docs-web-services-requests-mdx" */),
  "component---content-geoip-docs-web-services-responses-mdx": () => import("./../../../content/geoip/docs/web-services/responses.mdx" /* webpackChunkName: "component---content-geoip-docs-web-services-responses-mdx" */),
  "component---content-geoip-docs-web-services-sandbox-environment-mdx": () => import("./../../../content/geoip/docs/web-services/sandbox-environment.mdx" /* webpackChunkName: "component---content-geoip-docs-web-services-sandbox-environment-mdx" */),
  "component---content-geoip-geolite-2-free-geolocation-data-mdx": () => import("./../../../content/geoip/geolite2-free-geolocation-data.mdx" /* webpackChunkName: "component---content-geoip-geolite-2-free-geolocation-data-mdx" */),
  "component---content-geoip-geolocate-an-ip-client-side-javascript-mdx": () => import("./../../../content/geoip/geolocate-an-ip/client-side-javascript.mdx" /* webpackChunkName: "component---content-geoip-geolocate-an-ip-client-side-javascript-mdx" */),
  "component---content-geoip-geolocate-an-ip-databases-mdx": () => import("./../../../content/geoip/geolocate-an-ip/databases.mdx" /* webpackChunkName: "component---content-geoip-geolocate-an-ip-databases-mdx" */),
  "component---content-geoip-geolocate-an-ip-index-mdx": () => import("./../../../content/geoip/geolocate-an-ip/index.mdx" /* webpackChunkName: "component---content-geoip-geolocate-an-ip-index-mdx" */),
  "component---content-geoip-geolocate-an-ip-web-services-mdx": () => import("./../../../content/geoip/geolocate-an-ip/web-services.mdx" /* webpackChunkName: "component---content-geoip-geolocate-an-ip-web-services-mdx" */),
  "component---content-geoip-importing-databases-index-mdx": () => import("./../../../content/geoip/importing-databases/index.mdx" /* webpackChunkName: "component---content-geoip-importing-databases-index-mdx" */),
  "component---content-geoip-importing-databases-mysql-mdx": () => import("./../../../content/geoip/importing-databases/mysql.mdx" /* webpackChunkName: "component---content-geoip-importing-databases-mysql-mdx" */),
  "component---content-geoip-importing-databases-postgresql-mdx": () => import("./../../../content/geoip/importing-databases/postgresql.mdx" /* webpackChunkName: "component---content-geoip-importing-databases-postgresql-mdx" */),
  "component---content-geoip-index-mdx": () => import("./../../../content/geoip/index.mdx" /* webpackChunkName: "component---content-geoip-index-mdx" */),
  "component---content-geoip-privacy-exclusions-api-mdx": () => import("./../../../content/geoip/privacy-exclusions-api.mdx" /* webpackChunkName: "component---content-geoip-privacy-exclusions-api-mdx" */),
  "component---content-geoip-release-notes-2013-mdx": () => import("./../../../content/geoip/release-notes/2013.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2013-mdx" */),
  "component---content-geoip-release-notes-2014-mdx": () => import("./../../../content/geoip/release-notes/2014.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2014-mdx" */),
  "component---content-geoip-release-notes-2015-mdx": () => import("./../../../content/geoip/release-notes/2015.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2015-mdx" */),
  "component---content-geoip-release-notes-2016-mdx": () => import("./../../../content/geoip/release-notes/2016.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2016-mdx" */),
  "component---content-geoip-release-notes-2017-mdx": () => import("./../../../content/geoip/release-notes/2017.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2017-mdx" */),
  "component---content-geoip-release-notes-2018-mdx": () => import("./../../../content/geoip/release-notes/2018.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2018-mdx" */),
  "component---content-geoip-release-notes-2019-mdx": () => import("./../../../content/geoip/release-notes/2019.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2019-mdx" */),
  "component---content-geoip-release-notes-2020-mdx": () => import("./../../../content/geoip/release-notes/2020.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2020-mdx" */),
  "component---content-geoip-release-notes-2021-mdx": () => import("./../../../content/geoip/release-notes/2021.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2021-mdx" */),
  "component---content-geoip-release-notes-2022-mdx": () => import("./../../../content/geoip/release-notes/2022.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2022-mdx" */),
  "component---content-geoip-release-notes-2023-mdx": () => import("./../../../content/geoip/release-notes/2023.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2023-mdx" */),
  "component---content-geoip-release-notes-2024-mdx": () => import("./../../../content/geoip/release-notes/2024.mdx" /* webpackChunkName: "component---content-geoip-release-notes-2024-mdx" */),
  "component---content-geoip-updating-databases-mdx": () => import("./../../../content/geoip/updating-databases.mdx" /* webpackChunkName: "component---content-geoip-updating-databases-mdx" */),
  "component---content-geoip-upgrading-geoip-update-mdx": () => import("./../../../content/geoip/upgrading-geoip-update.mdx" /* webpackChunkName: "component---content-geoip-upgrading-geoip-update-mdx" */),
  "component---content-geoip-whats-new-in-geoip-2-mdx": () => import("./../../../content/geoip/whats-new-in-geoip2.mdx" /* webpackChunkName: "component---content-geoip-whats-new-in-geoip-2-mdx" */),
  "component---content-index-mdx": () => import("./../../../content/index.mdx" /* webpackChunkName: "component---content-index-mdx" */),
  "component---content-license-key-validation-api-mdx": () => import("./../../../content/license-key-validation-api.mdx" /* webpackChunkName: "component---content-license-key-validation-api-mdx" */),
  "component---content-maxmind-server-ip-addresses-mdx": () => import("./../../../content/maxmind-server-ip-addresses.mdx" /* webpackChunkName: "component---content-maxmind-server-ip-addresses-mdx" */),
  "component---content-minfraud-alerts-mdx": () => import("./../../../content/minfraud/alerts.mdx" /* webpackChunkName: "component---content-minfraud-alerts-mdx" */),
  "component---content-minfraud-api-documentation-mdx": () => import("./../../../content/minfraud/api-documentation.mdx" /* webpackChunkName: "component---content-minfraud-api-documentation-mdx" */),
  "component---content-minfraud-api-documentation-requests-mdx": () => import("./../../../content/minfraud/api-documentation/requests.mdx" /* webpackChunkName: "component---content-minfraud-api-documentation-requests-mdx" */),
  "component---content-minfraud-api-documentation-responses-mdx": () => import("./../../../content/minfraud/api-documentation/responses.mdx" /* webpackChunkName: "component---content-minfraud-api-documentation-responses-mdx" */),
  "component---content-minfraud-evaluate-a-transaction-mdx": () => import("./../../../content/minfraud/evaluate-a-transaction.mdx" /* webpackChunkName: "component---content-minfraud-evaluate-a-transaction-mdx" */),
  "component---content-minfraud-index-mdx": () => import("./../../../content/minfraud/index.mdx" /* webpackChunkName: "component---content-minfraud-index-mdx" */),
  "component---content-minfraud-minfraud-legacy-mdx": () => import("./../../../content/minfraud/minfraud-legacy.mdx" /* webpackChunkName: "component---content-minfraud-minfraud-legacy-mdx" */),
  "component---content-minfraud-normalizing-email-addresses-for-minfraud-mdx": () => import("./../../../content/minfraud/normalizing-email-addresses-for-minfraud.mdx" /* webpackChunkName: "component---content-minfraud-normalizing-email-addresses-for-minfraud-mdx" */),
  "component---content-minfraud-proxy-detection-mdx": () => import("./../../../content/minfraud/proxy-detection.mdx" /* webpackChunkName: "component---content-minfraud-proxy-detection-mdx" */),
  "component---content-minfraud-release-notes-2013-mdx": () => import("./../../../content/minfraud/release-notes/2013.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2013-mdx" */),
  "component---content-minfraud-release-notes-2014-mdx": () => import("./../../../content/minfraud/release-notes/2014.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2014-mdx" */),
  "component---content-minfraud-release-notes-2015-mdx": () => import("./../../../content/minfraud/release-notes/2015.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2015-mdx" */),
  "component---content-minfraud-release-notes-2016-mdx": () => import("./../../../content/minfraud/release-notes/2016.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2016-mdx" */),
  "component---content-minfraud-release-notes-2017-mdx": () => import("./../../../content/minfraud/release-notes/2017.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2017-mdx" */),
  "component---content-minfraud-release-notes-2018-mdx": () => import("./../../../content/minfraud/release-notes/2018.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2018-mdx" */),
  "component---content-minfraud-release-notes-2019-mdx": () => import("./../../../content/minfraud/release-notes/2019.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2019-mdx" */),
  "component---content-minfraud-release-notes-2020-mdx": () => import("./../../../content/minfraud/release-notes/2020.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2020-mdx" */),
  "component---content-minfraud-release-notes-2021-mdx": () => import("./../../../content/minfraud/release-notes/2021.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2021-mdx" */),
  "component---content-minfraud-release-notes-2022-mdx": () => import("./../../../content/minfraud/release-notes/2022.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2022-mdx" */),
  "component---content-minfraud-release-notes-2023-mdx": () => import("./../../../content/minfraud/release-notes/2023.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2023-mdx" */),
  "component---content-minfraud-release-notes-2024-mdx": () => import("./../../../content/minfraud/release-notes/2024.mdx" /* webpackChunkName: "component---content-minfraud-release-notes-2024-mdx" */),
  "component---content-minfraud-report-a-transaction-mdx": () => import("./../../../content/minfraud/report-a-transaction.mdx" /* webpackChunkName: "component---content-minfraud-report-a-transaction-mdx" */),
  "component---content-minfraud-sandbox-environment-mdx": () => import("./../../../content/minfraud/sandbox-environment.mdx" /* webpackChunkName: "component---content-minfraud-sandbox-environment-mdx" */),
  "component---content-minfraud-track-devices-mdx": () => import("./../../../content/minfraud/track-devices.mdx" /* webpackChunkName: "component---content-minfraud-track-devices-mdx" */),
  "component---content-minfraud-whats-new-in-minfraud-score-and-minfraud-insights-mdx": () => import("./../../../content/minfraud/whats-new-in-minfraud-score-and-minfraud-insights.mdx" /* webpackChunkName: "component---content-minfraud-whats-new-in-minfraud-score-and-minfraud-insights-mdx" */),
  "component---content-minfraud-working-with-transaction-dispositions-mdx": () => import("./../../../content/minfraud/working-with-transaction-dispositions.mdx" /* webpackChunkName: "component---content-minfraud-working-with-transaction-dispositions-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-search-results-tsx": () => import("./../../../src/pages/search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */)
}

