import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import responseJson from '../_examples/response';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={responseJson.ip_address.location} jsonPointer="/ip_address/location" name="Response | IP Address | Location" mdxType="MinFraudSchema">
      <p>{`This object contains city-level geolocation data associated with the IP
address associated with the event.`}</p>
      <Property name="local_time" services={['insights', 'factors']} tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The date and time of the transaction in the time zone associated with the
IP address. The value is formatted according to `}
          <a {...{
            "href": "https://tools.ietf.org/html/rfc3339",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`RFC 3339`}</a>
          {`.
For instance, the local time in Boston might be returned as
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`2015-04-27T19:17:24-04:00`}</inlineCode>
          {`.`}</p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      