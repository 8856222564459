import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import requestJson from '../_examples/request';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MinFraudSchema = makeShortcode("MinFraudSchema");
const Property = makeShortcode("Property");
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <MinFraudSchema json={requestJson.credit_card} jsonPointer="/credit_card" name="Request | Credit Card" services="*" mdxType="MinFraudSchema">
      <p>{`This object contains information provided by the end-user and the payment
processor about the credit card used for the for the event.`}</p>
      <Property name="issuer_id_number" tags={{
        'Max Length': 8
      }} mdxType="Property">
        <p>{`The issuer ID number for the credit card. This is the first six or eight
digits of the credit card number. It identifies the issuing bank. If you do
not know whether the IIN is six or eight digits long, send us six digits.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5450338695963-Credit-Card-and-Payments-Inputs#h_01G0YWJK0M3R4Y4N0AD2S6X9G4",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/credit_card/issuer_id_number`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="last_digits" tags={{
        'Max Length': 4
      }} mdxType="Property">
        <p>{`The last digits of the credit card number. In most cases, you should send
the last four digits for `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`last_digits`}</inlineCode>
          {`. If you send an
`}
          <a {...{
            "href": "#schema--request--credit-card__issuer_id_number",
            "parentName": "p"
          }}><inlineCode {...{
              "parentName": "a"
            }}>{`issuer_id_number`}</inlineCode></a>
          {`
that contains an eight digit IIN, and if the credit card brand is not one
of the following, you should send the last two digits for `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`last_digits`}</inlineCode>
          {`:
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Discover`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`JCB`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Mastercard`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`UnionPay`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Visa`}</inlineCode>
          {`.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5450338695963-Credit-Card-and-Payments-Inputs#h_01G0YWJW1KV6H4FEBWCMYA5HMW",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/credit_card/last_digits`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="token" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`A token uniquely identifying the card. The token should consist of non-space
printable ASCII characters. If the token is all digits, it must be more than
19 characters long. The token must not be a primary account number (PAN) or
a simple transformation of it. If you have a valid token that looks like a
PAN but is not one, you may prefix that token with a fixed string, e.g.,
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`token-`}</inlineCode>
          {`.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5450338695963-Credit-Card-and-Payments-Inputs#h_01G0YWHZK8D3N172REBAPXMQYY",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/credit_card/token`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property isDeprecated name="bank_name" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The name of the issuing bank as provided by the end user.`}</p>
      </Property>
      <Property isDeprecated name="bank_phone_country_code" tags={{
        'Max Length': 4
      }} mdxType="Property">
        <p>{`The phone country code for the issuing bank as provided by the end user. If
you provide this information then you must provide at least one digit.`}</p>
      </Property>
      <Property isDeprecated name="bank_phone_number" tags={{
        'Max Length': 255
      }} mdxType="Property">
        <p>{`The phone number, without the country code, for the issuing bank as provided
by the end user. Punctuation characters will be stripped. After stripping
punctuation characters, the number must contain only digits.`}</p>
      </Property>
      <Property name="country" tags={{
        'Max Length': 2
      }} mdxType="Property">
        <p>{`The two character `}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`ISO 3166-1 alpha-2 country
code`}</a>
          {` where the issuer of
the card is located. This may be passed instead of the
`}
          <a {...{
            "href": "#schema--request--credit-card__issuer_id_number",
            "parentName": "p"
          }}><inlineCode {...{
              "parentName": "a"
            }}>{`issuer_id_number`}</inlineCode></a>
          {` if
you do not wish to pass partial account numbers, or if your payment
processor does not provide them.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5450338695963-Credit-Card-and-Payments-Inputs#h_01G0Z6QZAYZ74FCVPN2PHZ4ZA8",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/credit_card/country`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="avs_result" tags={{
        'Max Length': 1
      }} mdxType="Property">
        <p>{`The address verification system (AVS) check result, as returned to you by
the credit card processor. The minFraud service supports the standard AVS
codes.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5450338695963-Credit-Card-and-Payments-Inputs#h_01G0YWK5CMEVG2YMTPQ11346QV",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/credit_card/avs_result`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="cvv_result" tags={{
        'Max Length': 1
      }} mdxType="Property">
        <p>{`The card verification value (CVV) code as provided by the payment processor.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5450338695963-Credit-Card-and-Payments-Inputs#h_01G0YWK5CMEVG2YMTPQ11346QV",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/credit_card/cvv_result`}</inlineCode>
            {` input on our Knowledge
Base.`}</a></p>
      </Property>
      <Property name="was_3d_secure_successful" mdxType="Property">
        <p>{`Whether the outcome of 3-D Secure verification (e.g. Safekey, SecureCode,
Verified by Visa) was successful. `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`true`}</inlineCode>
          {` if customer verification was
successful, or `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`false`}</inlineCode>
          {` if the customer failed verification. If 3-D Secure
verification was not used, was unavailable, or resulted in another outcome
other than success or failure, do not include this field.`}</p>
        <p><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/5450338695963-Credit-Card-and-Payments-Inputs#h_01G0YWK5CMEVG2YMTPQ11346QV",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about the `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`/credit_card/was_3d_secure_successfil`}</inlineCode>
            {` input on our
Knowledge Base.`}</a></p>
      </Property>
    </MinFraudSchema>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      